import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { themeOptions } from '../../themeConfig';
import { ScenarioItem } from './ScenarioItem/ScenarioItem';
import { SidebarDrawer, DrawerHeader, DrawerFooter } from './styledListComponents'
import SidebarFooterList from './SidebarFooterList/SidebarFooterList';
import SourceMenuItem from './SourceMenuItem/SourceMenuItem';
import ProjectMenuItem from './ProjectMenuItem/ProjectMenuItem';
import CalculateButton from './CalculateButton/CalculateButton';

export default function Sidebar({
  isSidebarOpen,
  setIsSidebarOpen,
  infoBoxDispatch,
  setDrawMode,
  projectId,
  activeSources,
  setNoiseResults }) {

  const sidebarThemeOptions = { ...themeOptions, palette: { ...themeOptions.palette, mode: 'dark' } }
  const sidebarTheme = createTheme(sidebarThemeOptions)
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <ThemeProvider theme={sidebarTheme}>
      <SidebarDrawer variant="permanent" isopen={isSidebarOpen}>
        <DrawerHeader />
        <Divider />
        <List>
          <ProjectMenuItem
            isSidebarOpen={isSidebarOpen}
            infoBoxDispatch={infoBoxDispatch} />
          <ScenarioItem
            isSidebarOpen={isSidebarOpen}
            infoBoxDispatch={infoBoxDispatch} />
          <SourceMenuItem
            isSidebarOpen={isSidebarOpen}
            infoBoxDispatch={infoBoxDispatch} />
          {/*<DrawItem isSidebarOpen={isSidebarOpen} setDrawMode={setDrawMode} />*/}
          <CalculateButton
            isSidebarOpen={isSidebarOpen}
            projectId={projectId}
            activeSources={activeSources}
            setNoiseResults={setNoiseResults} />
        </List>
        <DrawerFooter>
          <SidebarFooterList isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </DrawerFooter>
      </SidebarDrawer>
    </ThemeProvider>
  );
}
