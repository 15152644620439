import { TerrainLayer } from "@deck.gl/geo-layers";
import { TERRAIN_LAYER } from "../../constants";

const ELEVATION_DECODER = {
  rScaler: 6553.6,
  gScaler: 25.6,
  bScaler: 0.1,
  offset: -10000
};

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const ELEVATION_DATA_BASE_URL = "https://gatewave-gis-73l6zpenyq-ts.a.run.app"

export default function makeTerrainLayer(projectId, imageryId) {
  const surfaceImageUrl = `https://api.mapbox.com/styles/v1/${imageryId}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`;

  if (projectId == null) {
    return null
  }

  const defaultPixel = [1, 134, 160, 255]

  return new TerrainLayer({
    id: TERRAIN_LAYER,
    elevationDecoder: ELEVATION_DECODER,
    elevationData: `${ELEVATION_DATA_BASE_URL}/terrain/${projectId}/{z}/{x}/{y}.png`,
    texture: surfaceImageUrl,
    meshMaxError: 0.25,
    pickable: true,
    onTileError: (info) => {
      // Replace missing tiles with a no-data tile
      return {
        data: new Uint8Array(256 * 256 * 4).fill(...defaultPixel),
        x: info.x,
        y: info.y,
        z: info.z,
        status: 'LOADED',
      };
    },
    minZoom: 12,
    maxZoom: 18,
    updateTriggers: {
      elevationData: [projectId]
    },
    material: false,
    refinementStrategy: 'best-available'
    //operation: 'terrain+draw'
  })
}
