import { Drawer, ListItem, ListItemButton, ListItemIcon, styled } from "@mui/material";
import { SIDEBAR_WIDTH } from "../constants";


const openedMixin = (theme) => ({
  width: SIDEBAR_WIDTH,
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100px",
  backgroundImage: "url('/Gatewave_PatternW.png')",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: 1450
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  marginTop: `auto`,
}));

const sidebarBackgroundMixin = {
  backgroundImage: 'url(Gatewave_BlueWave_Bkg.jpg)',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  '&:before': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    display: 'block',
    background: '#071e2a',
    opacity: '0.5'
  }
}

const SidebarDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'isopen' })(
  ({ theme, isopen }) => ({
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isopen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': { ...openedMixin(theme), ...sidebarBackgroundMixin },
    }),
    ...(!isopen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': { ...closedMixin(theme), ...sidebarBackgroundMixin },
    })
  }),
);

function SidebarListItem({ ...props }) {
  return <ListItem {...props} sx={{ display: 'block' }} />
}

function SidebarListItemButton({ isSidebarOpen, ...props }) {
  return (
    <ListItemButton {...props}
      sx={{
        minHeight: 48,
        justifyContent: isSidebarOpen ? 'initial' : 'center',
        px: 2.5
      }} />
  )
}

function SidebarListItemIcon({ isSidebarOpen, ...props }) {
  return <ListItemIcon {...props}
    sx={{
      minWidth: 0,
      mr: isSidebarOpen ? 3 : 'auto',
      justifyContent: 'center'
    }} />
}

export {
  SidebarDrawer, DrawerHeader, DrawerFooter,
  SidebarListItem, SidebarListItemButton, SidebarListItemIcon
}