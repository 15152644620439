import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function EquipmentTab() {
  return (
    <>
      <Typography variant="h3">Equipment</Typography>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography>Nothing yet</Typography>
      </Box>
    </>
  )
}
