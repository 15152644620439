import { CLOSED_INFOBOX } from "../constants"

export default function infoBoxReducer(state, action) {
  // If a menu button is clicked while the menu is already active,
  // then close the menu.
  if (state === action.type) {
    return CLOSED_INFOBOX
  } else {
    return action.type
  }
}