import { Typography, Button, Grid } from '@mui/material';
import { useState } from 'react';
import AddSourceFormContents from '../../AddSourceFormContents/AddSourceFormContents';

export default function SourcePopupContent({
  source, projectId, sourceLocations,
  activeSourcesDispatch, zoomToSourceLocation
}) {

  const filteredSourceLocations = sourceLocations.filter(loc => loc.id === source.properties.id)

  const [showAddSourceForm, setShowAddSourceForm] = useState(false)

  let popupContent
  if (showAddSourceForm) {
    popupContent = <AddSourceFormContents
      projectId={projectId}
      sourceLocations={filteredSourceLocations}
      activeSourcesDispatch={activeSourcesDispatch}
      zoomToSourceLocation={() => console.log('zoom zoom')}
    />
  } else {
    popupContent = Object.keys(source.properties).map(property => {
      return <Typography variant="body2">
        {property}: {source.properties[property]}
      </Typography>
    })
  }

  return (
    <Grid
      container
      direction="column"
      minWidth="280px">
      <Grid item pb={1}>
        <Typography variant="h6">
          {source.properties.name}
        </Typography>
        <Typography variant="caption">
          Noise source location
        </Typography>
      </Grid>
      <Grid item>
        {popupContent}
      </Grid>
      <Grid item pt={2} textAlign={"center"}>
        <Button variant={showAddSourceForm ? "text" : "contained"}
          onClick={() => setShowAddSourceForm(!showAddSourceForm)}>
          {showAddSourceForm ? "View location info" : "Add noise source"}
        </Button>
      </Grid>
    </Grid>
  );
}
