import _ from "lodash"
import { BUILDING_LAYER, EMPTY_POPUP, RECEIVER_LAYER, SOURCE_LAYER } from "../constants"
import BuildingPopupContent from "./BuildingPopupContent/BuildingPopupContent"
import ReceiverPopupContent from "./ReceiverPopupContent/ReceiverPopupContent"
import SourcePopupContent from "./SourcePopupContent/SourcePopupContent"

function makePopupContent(feature, popupProps) {
  if (feature?.layer?.id == null) {
    return null
  }

  switch (feature.layer.id) {
    case BUILDING_LAYER:
      return {
        label: "Building " + feature.object.properties.RTA_ID,
        component: <BuildingPopupContent building={feature.object} {...popupProps} />
      }
    case SOURCE_LAYER:
      return {
        label: "Source " + feature.object.properties.name,
        component: <SourcePopupContent source={feature.object} {...popupProps} />
      }
    case RECEIVER_LAYER:
      return {
        label: "Receiver " + feature.object.properties.point_identifier,
        component: <ReceiverPopupContent receiver={feature.object} {...popupProps} />
      }
    default:
      return null
  }
}

function getCoords(feature) {
  let lat = feature.coordinate[1]
  let lon = feature.coordinate[0]
  let alt
  if (feature.coordinate.length < 3) {
    alt = 0
  } else {
    alt = feature.coordinate[2]
  }
  return { latitude: lat, longitude: lon, altitude: alt }
}

export default function popupReducer(state, action) {
  switch (action.type) {
    case 'openPopup':
      if (_.isEmpty(action.features)) {
        console.log('popupReducer: no features provided')
        return EMPTY_POPUP
      }
      const popupProps = action.popupProps
      const popupContent = action.features.map(feature => makePopupContent(feature, popupProps))
      const allCoords = action.features.map(getCoords)
      const coordinates = allCoords[0]
      const newPopupState = { 
        coordinates: coordinates, 
        allCoords: allCoords, 
        content: popupContent, 
        show: true 
      }
      return newPopupState;
    case 'useFeatureCoords':
      const updatedCoords = state.allCoords[action.index]
      return { ...state, coordinates: updatedCoords }
    case 'closePopup':
      return EMPTY_POPUP
    default:
      return state;
  }
}