import React from "react";
import { PROJECT_MENU, SCENARIO_MENU as LOAD_SCENARIO_MENU, SOURCE_MENU, SAVE_SCENARIO_MENU, SIDEBAR_WIDTH, SIDEBAR_MINI_WIDTH, CLOSED_INFOBOX } from "../constants";
import ProjectMenu from "./ProjectMenu/ProjectMenu"
import SourceMenu from "./SourceMenu/SourceMenu"
import LoadScenarioMenu from "./LoadScenarioMenu/LoadScenarioMenu"
import SaveScenarioMenu from "./SaveScenarioMenu/SaveScenarioMenu";
import { Button, Fade, Grid, Paper, Typography } from "@mui/material";

export default function InfoBox({ infoBox, ...props }) {

  const { infoBoxDispatch, isSidebarOpen, activeProject } = props

  const noProjectSelected = <Typography>No project selected</Typography>

  let infoBoxContent
  switch (infoBox) {
    case PROJECT_MENU:
      infoBoxContent = <ProjectMenu {...props} />
      break
    case SOURCE_MENU:
      if (activeProject?.id) {
        infoBoxContent = <SourceMenu {...props} />
      } else {
        infoBoxContent = noProjectSelected
      }
      break
    case LOAD_SCENARIO_MENU:
      if (activeProject?.id) {
        infoBoxContent = <LoadScenarioMenu {...props} />
      } else {
        infoBoxContent = noProjectSelected
      }
      break
    case SAVE_SCENARIO_MENU:
      if (activeProject?.id) {
        infoBoxContent = <SaveScenarioMenu {...props} />
      } else {
        infoBoxContent = noProjectSelected
      }
      break
    default:
      infoBoxContent = null
      break
  }
  const leftPosition = isSidebarOpen ? SIDEBAR_WIDTH + 10 : SIDEBAR_MINI_WIDTH + 10
  return <Fade direction="left" in={infoBox !== CLOSED_INFOBOX}>
    <Paper
      sx={{
        position: "absolute",
        top: 10,
        left: leftPosition,
        maxWidth: `calc(100vw - ${leftPosition}px - 280px)`,
        maxHeight: 'calc(100vh - 45px)',
        overflow: 'auto'
      }}>
      <Grid p={2} container direction="column">
        <Grid item>
          <Button onClick={() => infoBoxDispatch({ type: CLOSED_INFOBOX })}>Hide</Button>
        </Grid>
        <Grid item>
          {infoBoxContent}
        </Grid>
      </Grid>
    </Paper >
  </Fade>
}