import React, { useState, useRef, useReducer, useEffect } from "react";
import {
  AttributionControl,
  Layer,
  MapContext,
  NavigationControl,
  Popup,
  StaticMap
} from "react-map-gl";
import { DeckGL, FlyToInterpolator } from "deck.gl";
import {
  useTheme
} from "@mui/material"

import makeSourceLayer from "./mapLayers/SourceLayer/makeSourceLayer";
import makeReceiverLayer from "./mapLayers/ReceiverLayer/makeReceiverLayer";
import LegendBox from "./LegendBox/LegendBox";

import { Box } from "@mui/system";
import Sidebar from "./Sidebar/Sidebar";
import InfoBox from "./InfoBox/InfoBox";

import { getAndSetProjectDocuments, getAndSetProjectGeometry } from "./getAndSetProjectData";
import makeBuildingLayer from "./mapLayers/BuildingLayer/makeBuildingLayer";
import { landUseColourScheme, noiseLevelColourScheme } from "./mapLayers/colourSchemes";

import {
  EMPTY_GEOJSON, EMPTY_POPUP,
  PROJECT_MENU, SIDEBAR_WIDTH, VIEW,
  MAPBOX_ACCESS_TOKEN, SKY_LAYER, IMAGERY_OPTIONS,
  RENZO_LATITUDE, RENZO_LONGITUDE, NORMAL_NOISE_RESULT_TYPES, LAYER_DISPLAY_SETTINGS
} from "./constants";
import _ from "lodash";
import makeTerrainLayer from "./mapLayers/SiteTerrainLayer/makeTerrainLayer";
import popupReducer from "./PopupContent/popupReducer";
import PopupContent from "./PopupContent/PopupContent";
import handleMapClick from "./handleMapClick";
import infoBoxReducer from "./InfoBox/infoBoxReducer";
import { activeSourcesReducer } from "./reducers/activeSourcesReducer";
import { geometryReducer } from "./reducers/geometryReducer";
import drawnFeaturesReducer from "./reducers/drawnFeaturesReducer";
import makeTemporaryFeatureLayer from "./mapLayers/TemporaryFeatureLayer/TemporaryFeatureLayer";
import handleMapHover from "./handleMapHover";
import makeDrawnFeaturesLayer from "./mapLayers/DrawnFeaturesLayer/makeDrawnFeaturesLayer";
//import handleMapDoubleClick from "./handleMapDoubleClick";
import handleMapDrag from "./handleMapDrag";
import { handleViewportChange } from "./geometryFunctions";
import DisplayOptionsBox from "./DisplayOptionsBox/DisplayOptionsBox";
import layerDisplaySettingsReducer from "./reducers/layerDisplaySettingsReducer";

const colourSchemeOptions = [
  { 'label': 'Land use', 'type': 'landuse', 'scheme': landUseColourScheme },
  { 'label': 'Noise level', 'type': 'noiselevel', 'scheme': noiseLevelColourScheme }
]

const openedMixin = (theme) => ({
  left: SIDEBAR_WIDTH,
  width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  overflowX: 'hidden',
  overflowY: "hidden"
});

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  left: `calc(${theme.spacing(7)} + 1px)`,
  width: `calc(100vw - ${theme.spacing(7)} - 1px)`,
  [theme.breakpoints.up('sm')]: {
    left: `calc(${theme.spacing(8)} + 1px)`,
    width: `calc(100 vw - ${theme.spacing(8)} - 1px)`,
  },
  overflowY: "hidden"
});


export default function MapInterface() {
  // Project
  const [activeProject, setActiveProject] = useState({})

  // Project level data - for mapping
  const [receivers, receiversDispatch] = useReducer(geometryReducer, [])
  const [sourceGeometry, sourceGeometryDispatch] = useReducer(geometryReducer, [])
  const [buildings, buildingsDispatch] = useReducer(geometryReducer, [])
  const [drawnFeatures, drawnFeaturesDispatch] = useReducer(drawnFeaturesReducer, { alreadyDrawn: EMPTY_GEOJSON, nextVertex: [], fixedVertices: [] })
  const [projectExtent, projectExtentDispatch] = useReducer(geometryReducer, [])
  // Project level data - non-spatial
  const [sourceLocations, setSourceLocations] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [activeScenario, setActiveScenario] = useState({})
  const [currentImageryStyle, setCurrentImageryStyle] = useState(
    IMAGERY_OPTIONS[0]
  );
  //const [criteriaOptions, setCriteriaOptions] = useState([])
  // noise results
  const [noiseResults, setNoiseResults] = useState({})

  useEffect(() => {
    // reset project data
    // reset geometry
    receiversDispatch({ type: 'reset' })
    sourceGeometryDispatch({ type: 'reset' })
    buildingsDispatch({ type: 'reset' })
    projectExtentDispatch({ type: 'reset' })
    // reset non-geometry
    setSourceLocations([])
    setScenarios([])
    setActiveScenario({})

    if (activeProject && activeProject.id) {
      console.log('activeProject is defined and useEffect has triggered')
      getAndSetProjectGeometry(
        activeProject, receiversDispatch,
        sourceGeometryDispatch, buildingsDispatch,
        projectExtentDispatch)
      getAndSetProjectDocuments(activeProject, setScenarios, setSourceLocations)
    }
  }, [activeProject])

  useEffect(() => {
    try {
      handleViewportChange(projectExtent, setViewport)
    } catch {
      console.log('could not zoom to project extent')
    }
  }, [projectExtent])

  useEffect(() => {
    // adulterate the buildings
    console.log('noise results have changed')
    buildingsDispatch({ type: 'attachBuildingNoiseLevels', levels: noiseResults })
  }, [noiseResults])

  // reducers
  const [activeSources, activeSourcesDispatch] = useReducer(activeSourcesReducer, [])
  const [popup, popupDispatch] = useReducer(popupReducer, EMPTY_POPUP)
  const [infoBox, infoBoxDispatch] = useReducer(infoBoxReducer, PROJECT_MENU)

  // Other state
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [colourScheme, setColourScheme] = useState(colourSchemeOptions[0])
  const [viewport, setViewport] = useState({
    latitude: RENZO_LATITUDE,
    longitude: RENZO_LONGITUDE,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    maxPitch: 80,
    transitionInterpolator: new FlyToInterpolator()
  });
  const [drawMode, setDrawMode] = useState(VIEW)
  const [activeNoiseResultType, setActiveNoiseResultType] = useState('standard')
  const [noiseResultTypes, setNoiseResultTypes] = useState(NORMAL_NOISE_RESULT_TYPES) // eslint-disable-line
  const [layerDisplaySettings, layerDisplaySettingsDispatch] = useReducer(layerDisplaySettingsReducer, LAYER_DISPLAY_SETTINGS)
  // refs
  const mapRef = useRef();
  const deckRef = useRef()

  const projectId = activeProject && activeProject.id ? activeProject.id : null
  

  // Make layers
  const terrainLayer = makeTerrainLayer(projectId, currentImageryStyle.value);
  const receiverLayer = makeReceiverLayer(receivers, layerDisplaySettings);
  const sourceLayer = makeSourceLayer(
    sourceGeometry,
    activeSources,
    layerDisplaySettings
  )
  const buildingLayer = makeBuildingLayer(
    buildings,
    colourScheme,
    activeNoiseResultType,
    layerDisplaySettings
  )
  const drawnFeaturesLayer = makeDrawnFeaturesLayer(drawnFeatures, layerDisplaySettings)
  const temporaryFeatureLayer = makeTemporaryFeatureLayer(drawnFeatures)
  const deckLayers = [receiverLayer, sourceLayer, buildingLayer, temporaryFeatureLayer, drawnFeaturesLayer, terrainLayer]

  // Helper function to facilitate zoom-to-location
  function zoomToSourceLocation(location) {
    if (!location || !location.id || _.isEmpty(sourceGeometry)) {
      return
    }
    const geometryOfInterest = sourceGeometry.find(feature => feature.properties.id === location.id)
    if (geometryOfInterest) {
      handleViewportChange(geometryOfInterest, setViewport)
    }
  }

  const theme = useTheme();

  const popupProps = {
    projectId: projectId,
    sourceLocations: sourceLocations,
    activeSourcesDispatch: activeSourcesDispatch,
    zoomToSourceLocation: zoomToSourceLocation
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        infoBoxDispatch={infoBoxDispatch}
        setDrawMode={setDrawMode}
        projectId={projectId}
        activeSources={activeSources}
        setNoiseResults={setNoiseResults}
      />
      <DeckGL
        ref={deckRef}
        layers={deckLayers}
        initialViewState={viewport}
        controller={true}
        ContextProvider={MapContext.Provider}
        isSidebarOpen={isSidebarOpen}
        style={{
          ...(isSidebarOpen ? openedMixin(theme) : closedMixin(theme)),
          position: 'fixed'
        }}
        onClick={(event, info) => handleMapClick(event, info, deckRef, popupDispatch,
          drawMode, setDrawMode, drawnFeaturesDispatch, popupProps)}
        onHover={info => handleMapHover(info, deckRef, drawMode, drawnFeaturesDispatch)}
        onDrag={(info, event) => handleMapDrag(info, event, deckRef, drawMode, drawnFeaturesDispatch)}
      >
        <StaticMap
          ref={mapRef}
          mapStyle={`mapbox://styles/${currentImageryStyle.value}`}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          clickRadius={12}
          attributionControl={false}
        >
          <Layer {...SKY_LAYER} />
        </StaticMap>
        {popup.show &&
          <Popup
            {...popup.coordinates}
            onClose={() => popupDispatch({ type: 'closePopup' })}
            maxWidth='300px'
          >
            <PopupContent popup={popup} popupDispatch={popupDispatch} />
          </Popup>
        }
        <NavigationControl style={{ left: 10, bottom: 40 }} />
        <AttributionControl style={{ right: 0, bottom: 0 }} />
      </DeckGL>
      <LegendBox
        colourScheme={colourScheme}
        allColourSchemes={colourSchemeOptions}
        setColourScheme={setColourScheme}
        activeNoiseResultType={activeNoiseResultType}
        setActiveNoiseResultType={setActiveNoiseResultType}
        noiseResultTypes={noiseResultTypes} />
      <DisplayOptionsBox
        currentImageryStyle={currentImageryStyle}
        setCurrentImageryStyle={setCurrentImageryStyle}
        layerDisplaySettings={layerDisplaySettings}
        layerDisplaySettingsDispatch={layerDisplaySettingsDispatch} />
      <InfoBox
        infoBox={infoBox}
        activeSources={activeSources}
        activeSourcesDispatch={activeSourcesDispatch}
        sourceLocations={sourceLocations}
        infoBoxDispatch={infoBoxDispatch}
        isSidebarOpen={isSidebarOpen}
        zoomToSourceLocation={zoomToSourceLocation}
        scenarios={scenarios}
        activeProject={activeProject}
        setActiveProject={setActiveProject}
        activeScenario={activeScenario}
        setActiveScenario={setActiveScenario}
      />
    </Box >
  )
}