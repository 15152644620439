import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Box, Button, CircularProgress, FormControl, FormGroup, FormHelperText, FormLabel, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab"
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";


export default function Login() {
  const [formType, setFormType] = useState("login")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return
    }
    if (user) {
      return navigate("/")
    };
  }, [user, loading]); //eslint-disable-line react-hooks/exhaustive-deps

  const formContents = () => {
    switch (formType) {
      case 'login':
        return <>
          <FormLabel>Log in to Gatewave</FormLabel>
          <FormControl>
            <TextField
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
              margin="normal"
              size="small"
            />
          </FormControl>
          <FormControl>
            <TextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              margin="normal"
              size="small"
            />
          </FormControl>
          {loading ? <CircularProgress /> :
            <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmitLogin} type="submit">
              Login
            </LoadingButton>}
        </>
      case 'reset':
        return <>
          <FormLabel>Request password reset</FormLabel>
          <FormControl>
            <TextField
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
              margin="normal"
              size="small"
            />
          </FormControl>
          {loading ? <CircularProgress /> :
            <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmitReset} type="submit">
              Reset
            </LoadingButton>}
        </>
      case 'resetRequested':
        return <Typography>An email has been sent to {email} with reset instructions.</Typography>
      default:
        return
    }
  }

  const bottomButton = () => {
    switch (formType) {
      case 'login':
        return <Button color="secondary" onClick={() => setFormType("reset")}>Forgot password?</Button>
      case 'reset':
      case 'resetRequested':
        return <Button color="secondary" onClick={() => setFormType("login")}>Go to login</Button>
      default:
        return 
    }
  }

  function handleSubmitLogin(e) {
    e.preventDefault()
    setErrorMessage("")
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password).catch((reason) => {
      console.log('there was an issue logging in')
      console.log(JSON.stringify(reason))
      switch (reason.code) {
        case "auth/invalid-email" || "auth/invalid-password" || "auth/wrong-password":
          setErrorMessage("Invalid email or password")
          break;
        case "auth/network-request-failed":
          setErrorMessage("Network issue")
          break;
        default:
          setErrorMessage("There was an issue logging in")
          break;
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function handleSubmitReset(e) {
    e.preventDefault()
    setErrorMessage("")
    setIsLoading(true)
    sendPasswordResetEmail(auth, email).then(() => {
      setIsLoading(false)
      setFormType("resetRequested")
    }).catch((reason) => {
      //console.log('there was an issue sending password reset')
      //console.log(JSON.stringify(reason))
    }).finally(() => {
      setIsLoading(false)
      setFormType("resetRequested")
    })
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      minHeight="100vh"
      style={{
        background: "linear-gradient(rgba(117,162,187,1) 0%, rgba(26,101,143,1) 35%, rgba(15,60,85,1) 85%)"
      }}
    >
      <Box
        maxWidth="300px"
        component="form"
        onSubmit={handleSubmitLogin}
        bgcolor="white"
        boxShadow={3}
        p="30px"
        borderRadius={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="50%"
          maxWidth="100px"
          component="img"
          src="logo384.png"
          mb={3}
        />
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
          {formContents()}
          <FormHelperText error>{errorMessage}</FormHelperText>
        </FormGroup>
        <Box mt={2}>
          {bottomButton()}
        </Box>
      </Box>
    </Box >
  );
}
