import { Autocomplete, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import BackNextButtons from '../BackNextButtons'

export default function Step3Direction({ noiseSource, noiseSourceDispatch, handleNext, handleBack }) {
  const [direction, setDirection] = useState({})
  const [allDirections, setAllDirections] = useState([])

  // this should fire each time the step is selected
  // but if it doesn't, add noiseSource to the dependency array
  useEffect(() => {
    let locationIsValid = noiseSource && noiseSource.location && noiseSource.location.id
    let sourceIsValid = noiseSource && noiseSource.source && noiseSource.source.id
    let sourceDirections = locationIsValid && sourceIsValid && noiseSource.source.directions
    if (!_.isEmpty(sourceDirections)) {
      console.log(sourceDirections)
      setAllDirections(sourceDirections)
      setDirection(sourceDirections[0])
    }

  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit() {
    noiseSourceDispatch({ type: "updateDirection", direction: direction })
    handleNext()
  }

  const nextButtonDisabled = allDirections.length === 0 && _.isEmpty(direction)
  

  return (
    <>
      {allDirections.length === 0 ?
        <Typography>No direction options for this noise source</Typography>
        : allDirections.length > 1 ?
          <Autocomplete
            id="direction-select"
            options={allDirections}
            value={direction}
            getOptionLabel={(option) => option && option.id}
            renderInput={(params) => <TextField {...params} label="Select direction" />}
            onChange={(e, selectedDirection) => setDirection(selectedDirection)}
          />
          : <Typography>One direction option for this location: {direction.id}</Typography>}
      <BackNextButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        nextButtonDisabled={nextButtonDisabled}
      />
    </>
  )
}
