import { auth } from "../../firebase"

const CREATE_USER_ENDPOINT = "https://gatewave-admin-73l6zpenyq-ts.a.run.app/createUser"
//const CREATE_USER_ENDPOINT = "http://127.0.0.1:8083/createUser"

export async function createUser(user, authToken) {
  // const { name, email, isAdmin, isEngineer, canAccessProjects, canEditProjects } = user

  const response = await fetch(CREATE_USER_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user)
  })

  const responseText = await response.text()
  const feedback = {email: user.email, responseText: responseText}

  if (!response.ok) {
    return Promise.reject(feedback)
  }
  
  return feedback
}

export default async function createUsers(newUsers) {
  const authToken = await auth.currentUser.getIdToken()
  const newUsersPromises = newUsers.map((user) => {
    return createUser(user, authToken)
  })
  return await Promise.allSettled(newUsersPromises)
}