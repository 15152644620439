import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      minHeight="100vh"
      style={{
        background: "linear-gradient(rgba(117,162,187,1) 0%, rgba(26,101,143,1) 35%, rgba(15,60,85,1) 85%)"
      }}
    >
      <Box
        maxWidth="400px"
        bgcolor="white"
        boxShadow={3}
        p="30px"
        borderRadius={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="50%"
          maxWidth="100px"
          component="img"
          src="logo384.png"
          mb={3}
        />
        <Grid container direction="column" alignItems="right">
          <Grid item>
            <Typography>Gatewave encountered an unexpected error.</Typography>
          </Grid>
          <Grid item>
            <Typography>Please contact the Gatewave team and describe how this issue was encountered.</Typography>
          </Grid>
          <Grid item>
            <Typography>We apologise for any inconvenience.</Typography>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Button variant="contained" onClick={() => navigate("/")}>Reload</Button>
        </Box>
      </Box>
    </Box >

  )
}
