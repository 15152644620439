export const exceedanceBands = {
  "26 or higher": [25, 999],
  "16 to 25": [15, 25],
  "6 to 15": [5, 15],
  "1 to 5": [0, 5],
  "Complies": [-999, 0]
}

export const exceedanceBandColours = {
  "26 or higher": [143, 57, 0],
  "16 to 25": [255, 164, 0],
  "6 to 15": [255, 255, 0],
  "1 to 5": [136, 213, 90],
  "Complies": [0, 93, 21]
}

export const VARIABLE = 'variable'

export const SIDEBAR_WIDTH = 240
export const SIDEBAR_MINI_WIDTH = 60

export const BLANK_GEOJSON = { type: "FeatureCollection", features: [] };

export const MIN_VIEWPORT_WIDTH = 200 // metres
export const MIN_VIEWPORT_HEIGHT = 180 // metres

// Source menu
export const ADD_SOURCE = 'Add source'
export const ACTIVE_SOURCES = 'Active sources'

// layer IDs
export const BUILDING_LAYER = 'buildingLayer'
export const SOURCE_LAYER = 'sourceLayer'
export const RECEIVER_LAYER = 'receiverLayer'
export const TERRAIN_LAYER = 'terrainLayer'
export const DRAWN_FEATURES_LAYER = 'drawnFeaturesLayer'
export const WORKAREA_LAYER = 'workareaLayer'
export const TEMPORARY_FEATURE_LAYER = 'temporaryFeatureLayer'

// infobox
export const PROJECT_MENU = 'projectMenu'
export const SCENARIO_MENU = 'scenarioMenu'
export const SOURCE_MENU = 'sourceMenu'
export const REPORT_MENU = 'reportMenu'
export const CLOSED_INFOBOX = 'closed'
export const SAVE_SCENARIO_MENU = 'saveScenarioMenu'

// popup
export const EMPTY_POPUP = { coordinates: { latitude: 0, longitude: 0, altitude: 0 }, content: [], show: false }

// geojson
export const EMPTY_GEOJSON = {
  type: 'FeatureCollection',
  features: []
}

// scenario
export const EMPTY_SCENARIO = { id: null, reference: null, name: null, description: null, template: false, deleted: false }

// draw modes
export const VIEW = 'view'
export const DRAW_WORKAREA = 'draw_workarea'
export const DRAW_OBSERVATION = 'draw_observation'
export const MEASURE = 'measure'
export const MEASURE_AREA = 'measure_area'
export const EDIT = 'edit'
export const DELETE = 'delete'
export const DRAWING_MODES = [EDIT, DRAW_WORKAREA, DRAW_OBSERVATION]

export const WORKAREA = 'workarea'
export const OBSERVATION = 'observation'
export const STANDARD_PROPERTIES = {
  [DRAW_WORKAREA]: {type: WORKAREA},
  [DRAW_OBSERVATION]: {type: OBSERVATION}
}

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const SKY_LAYER = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [0.0, 0.0],
    "sky-atmosphere-sun-intensity": 15,
  },
};

export const IMAGERY_OPTIONS = [
  {
    name: "Satellite",
    value: "armorr/cl3s0j140001615qawmha7hgj", 
  },
  {
    name: "Street (dark)",
    value: "armorr/ckck0xec04ktd1jmhmv1cu5hm",
  },
  {
    name: "Street (light)",
    value: "armorr/cl9dgnicz000315p7uxrbrkec",
  },
];

export const RENZO_LATITUDE = -33.886387;
export const RENZO_LONGITUDE = 151.208682;

export const MAX_ZOOM_FOR_VIEWPORT_CHANGE = 18

export const NORMAL_NOISE_RESULT_TYPES = {'standard': "Standard", 'noise enhancing': "Noise enhancing"}

export const LAYER_DISPLAY_SETTINGS = {
  'showBuildings': true,
  'showReceivers': true,
  'showResidential': true,
  'showMixedUse': true,
  'showNonResidential': true
}