import { hexToRgb } from "../../utils";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DEFAULT_COLOUR } from "../colourSchemes";
import { BUILDING_LAYER, EMPTY_GEOJSON } from "../../constants";
import _ from "lodash";

function makeBandName(level) {
  const bandWidth = 5
  const lowerLimit = 35
  const upperLimit = 75

  let assessableLevel = Math.round(level)
  if (assessableLevel <= lowerLimit) {
    return `${lowerLimit} or less`
  }
  if (assessableLevel > upperLimit) {
    return `${upperLimit + 1} or higher`
  }
  let lowerBound = Math.floor(level / bandWidth) * bandWidth + 1;
  let upperBound = lowerBound + bandWidth - 1;
  if (assessableLevel % bandWidth < 0.5) {
    upperBound = assessableLevel
    lowerBound = assessableLevel - bandWidth + 1
  }
  // Return the range bounds as a string
  return lowerBound + "-" + upperBound;
}

function assignFeatureColour(building, colourScheme, activeNoiseResultType) {
  // TODO use the colour scheme to drive the colour selection
  // TODO need to know how to pull data off the building correctly
  switch (colourScheme.type) {
    case 'landuse':
      const rectype = building.properties.rectype
      if (rectype && colourScheme.scheme[rectype]) {
        return hexToRgb(colourScheme.scheme[rectype])
      }
      return hexToRgb(DEFAULT_COLOUR)

    case 'noiselevel':
      const level = building.properties.level && building.properties.level[activeNoiseResultType]
      if (!level) {
        return hexToRgb(DEFAULT_COLOUR)
      }
      const band = makeBandName(level)
      if (!colourScheme.scheme[band]) {
        return hexToRgb(DEFAULT_COLOUR)
      }

      return hexToRgb(colourScheme.scheme[band])
    default:
      return hexToRgb(DEFAULT_COLOUR)
  }

}

function buildingsToDisplay(buildings, layerDisplaySettings) {
  if (_.isEmpty(layerDisplaySettings)) {
    // if no settings are given, display everything
    return buildings
  }

  if (Object.keys(layerDisplaySettings).includes('showBuildings')
    && !layerDisplaySettings.showBuildings) {
    return EMPTY_GEOJSON
  }

  const toDisplay = buildings.filter(building => {
    switch (building.properties.rectype) {
      case 'Residential':
        return layerDisplaySettings.showResidential
      case 'Mixed-use':
        return layerDisplaySettings.showMixedUse
      default:
        return layerDisplaySettings.showNonResidential
    }
  })
  return toDisplay
}

export default function makeBuildingLayer(
  buildings,
  colourScheme,
  activeNoiseResultType,
  layerDisplaySettings
) {

  return new GeoJsonLayer({
    id: BUILDING_LAYER,
    data: buildingsToDisplay(buildings, layerDisplaySettings),
    pickable: true,
    stroked: true,
    extruded: true,
    wireframe: true,
    getElevation: (building) => building.properties.HA,
    // TODO need to revert manually defined colour scheme back to data driven option
    getFillColor: (building) => assignFeatureColour(building, colourScheme, activeNoiseResultType),
    getLineColor: (building) => building.properties.heritage ? [239, 47, 47] : [0, 0, 0],
    updateTriggers: {
      getFillColor: [colourScheme, activeNoiseResultType, layerDisplaySettings]
    }
  });
}