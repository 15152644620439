import _ from "lodash";

export function geometryReducer(state, action) {
  // expects:
  // {type: 'add', features: [array]}
  // {type: 'attachBuildingNoiseLevels', levels: {'standard': {buildingId: float, ...}, 'noiseenhancing': {}}}
  // {type: 'reset'}
  if (action.type === 'reset') {
    return [];
  }
  switch (action.type) {
    case 'add':
      return [...state, ...action.features]
    case 'attachBuildingNoiseLevels':
      const allLevels = action.levels
      if (_.isEmpty(allLevels)) {
        return state
      }
      let newState = [...state]
      newState.forEach(feature => {
        const buildingId = feature.properties['RTA_ID']
        let newLevels = {}
        Object.keys(action.levels).forEach(levelType => {
          newLevels[levelType] = allLevels[levelType][buildingId]
        })
        feature.properties.level = newLevels
      })
      return newState
    default:
      console.log('geometryReducer: unknown action ', action.type)
      return state;
  }
}