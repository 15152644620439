import { Autocomplete, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useState } from 'react'

export default function SubSourceForm({ subsource, activeSubSourcesDispatch }) {
  const [isActive, setIsActive] = useState(false)
  const [operatingState, setOperatingState] = useState()

  function handleActivation(e) {
    let newActivationValue = e.target.checked
    setIsActive(newActivationValue)

    if (newActivationValue === false) {
      activeSubSourcesDispatch({type: 'deactivate', subsourceId: subsource.id})
      return
    }

    if (subsource.settings && subsource.settings.length > 1 && _.isEmpty(operatingState)) {
      activeSubSourcesDispatch({type: 'deactivate', subsourceId: subsource.id})
      return
    }

    let {settings, ...subSourceEntry} = subsource
    if (settings && operatingState) {
      subSourceEntry.setting = operatingState
    }
    console.log(operatingState)
    console.log('handleActivation: dispatch subSourceEntry')
    activeSubSourcesDispatch({type: 'activate', subsource: subSourceEntry})
    
  }

  function handleStateSelect(e, selectedState) {
    setOperatingState(selectedState)
    if (isActive) {
      if (subsource.settings && subsource.settings.length > 1 && _.isEmpty(selectedState)) {
        activeSubSourcesDispatch({type: 'deactivate', subsourceId: subsource.id})
        return
      }

      let {settings, ...subsourceBits} = subsource
      let subSourceEntry = {...subsourceBits, setting: selectedState}
      console.log('handleStateSelect: dispatch subSourceEntry')
      activeSubSourcesDispatch({type: 'activate', subsource: subSourceEntry})
    }
  }

  return (
    <>
      <Grid container py={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2">{subsource.id}</Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            label={isActive ? "Active" : "Inactive"}
            control={<Switch
              checked={isActive} onChange={handleActivation} />}
          />
        </Grid>
        {subsource.settings &&
          <Grid item>
            <Autocomplete
              size="small"
              id="subsource-state-select"
              disabled={!isActive}
              options={subsource.settings}
              value={operatingState}
              getOptionLabel={(option) => option ? option.id : ""}
              renderInput={(params) => <TextField {...params} label="Select operating state" />}
              onChange={handleStateSelect} 
              sx={{minWidth: 250}}/>
          </Grid>}
      </Grid>
    </>
  )
}
