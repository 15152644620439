import { Autocomplete, Grid, TextField } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { getSourcesAtLocation } from "../../../queries/getDataQueries"
import BackNextButtons from '../BackNextButtons'

export default function Step2Source({ noiseSource, projectId, noiseSourceDispatch, handleNext, handleBack }) {
  // Step 1: select location
  const [allSources, setAllSources] = useState([])
  const [source, setSource] = useState()

  // this should fire each time the tab is selected (step contents should be unmounted on change)
  // if there's issues then consider adding location to the dependency array
  useEffect(() => {
    if (projectId == null) {
      console.log('project id is null')
      return;
    }
    const location = noiseSource.location
    if (location == null) {
      console.log('location is null')
      return;
    }
    const locationId = location.id
    if (locationId == null) {
      console.log('location id is null')
      return;
    }
    console.log(`${projectId}/SourceLocation/${locationId}/Sources`)
    getSourcesAtLocation(projectId, locationId).then(result => {
      console.log('locationSources: ', result)
      setAllSources(result)
    })
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('check if noiseSource already has a source and if so, check it is a valid choice')

  }, [allSources])

  function handleSubmit() {
    noiseSourceDispatch({ type: "updateSource", source: source })
    handleNext()
  }

  const nextButtonDisabled = _.isEmpty(source)
  

  return (
    <>
      <Grid item pb={1}>
        <Autocomplete
          id="source-select"
          options={allSources}
          value={source}
          getOptionLabel={(option) => option ? option.id : ""}
          renderInput={(params) => <TextField {...params} label="Select source" />}
          onChange={(e, selectedSource) => setSource(selectedSource)}
        />
      </Grid>
      <BackNextButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        nextButtonDisabled={nextButtonDisabled}
      />
    </>
  )
}
