import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, ListItemText } from '@mui/material'
import { SidebarListItem, SidebarListItemButton, SidebarListItemIcon } from './styledListComponents';


export default function BaseItem({ text, icon, isSidebarOpen, subList, onClick }) {
  const [isSublistOpen, setIsSublistOpen] = useState(false)

  let expandIcon;
  if (isSidebarOpen) {
    expandIcon = isSublistOpen ? <ExpandLess /> : <ExpandMore />
  } else {
    expandIcon = isSublistOpen ? <ChevronLeft /> : <ChevronRight />
  }

  // function for opening the sublist
  function handleClick() {
    if (subList) {
      setIsSublistOpen(!isSublistOpen)
    }
    if (onClick) {
      onClick()
    } 
    if (!subList && !onClick) {
      console.log("no sublist or onclick for " + text)
    }
  }

  return (
    <SidebarListItem key={text} disablePadding>
      <SidebarListItemButton
        isSidebarOpen={isSidebarOpen}
        onClick={handleClick}
      >
        <SidebarListItemIcon isSidebarOpen={isSidebarOpen}>
          {icon}
        </SidebarListItemIcon>
        {isSidebarOpen ? <ListItemText primary={text} /> : null}
        {subList ? expandIcon : null}
      </SidebarListItemButton>
      {subList ? isSidebarOpen ?
        <Collapse in={isSublistOpen}>
          {subList}
        </Collapse>
        : null
        : null}
    </SidebarListItem>
  )
}
