export const themeOptions = {
  palette: {
    primary: {
      main: '#1a658f',
    },
    secondary: {
      main: '#68A4CA',
    },
    error: {
      main: '#f74726',
    },
    warning: {
      main: '#ff9f00',
    },
    info: {
      main: '#2b9def',
    },
    success: {
      main: '#63b438',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(","),
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
};