import { PointCloudLayer } from "@deck.gl/layers";
import { exceedanceBandColours, exceedanceBands, RECEIVER_LAYER } from "../../constants"


function assignFeatureColour(exceedance) {

  const band = Object.keys(exceedanceBands).find(
    (b) =>
      exceedance > exceedanceBands[b][0] &&
      exceedance <= exceedanceBands[b][1]
  );
  if (band == null) {
    return [80, 102, 103]
  } else {
    return exceedanceBandColours[band]
  }
}


function makeReceiverLayer(
  receivers,
  exceedances
) {
  const data = receivers.features;
  let pointSize = 7

  return new PointCloudLayer({
    id: RECEIVER_LAYER,
    data,
    getPosition: (d) => d.geometry.coordinates,
    pickable: true,
    pointSize: pointSize,
    material: false,
    getColor: (receiver) => assignFeatureColour(exceedances[receiver.properties.BEZ]),
    updateTriggers: {
      getColor: [exceedances]
    },
  });
}

export default makeReceiverLayer;
