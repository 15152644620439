import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function PopupContent({ popup, popupDispatch }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    popupDispatch({type: 'useFeatureCoords', index: newValue})
  };

  return (
    <Box sx={{ width: '100%' }}>
      {popup.content && popup.content.length > 1 &&
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable">
            {popup.content.map(item => {
              return <Tab label={item.label} />
            })}
          </Tabs>
        </Box>}
      {popup.content.map((item, index) => {
        return <TabPanel value={value} index={index}>
          {item.component}
        </TabPanel>
      })}
    </Box>
  );
}