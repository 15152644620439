import { auth } from "./firebase"
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from "./Login/Login";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Reset from "./Reset/Reset";
import { CircularProgress, Grid } from "@mui/material";
import MapInterface from "./MapInterface/MapInterface";
import AdminPanel from "./AdminPanel/AdminPanel";
import ErrorPage from "./ErrorPage/ErrorPage";


function LoadingSpinner() {
  return <Grid container direction="column" alignItems="center" minHeight="100vh" justifyContent="center">
  <Grid item>
    <CircularProgress />
  </Grid>
</Grid>
}


function App() {
  const [user, loading] = useAuthState(auth);
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: loading ? <LoadingSpinner /> : (user ? <MapInterface /> : <Navigate to="/login" />),
        errorElement: <ErrorPage />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/reset",
        element: <Reset />
      },
      {
        path: "/admin",
        element: loading ? <LoadingSpinner /> : (user ? <AdminPanel /> : <Navigate to="/login" />),
        errorElement: <ErrorPage />
      }
    ]
  )

  return (
    <RouterProvider router={router} />
  );
}

export default App;
