import { Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import React from 'react'


function SubsourceCard({ subsource }) {
  if (!subsource) {
    return
  }
  return <>
    <Grid container alignItems="center">
      <Grid pb={2} item xs={12} sm={3}>
        <Typography variant="h6">{subsource.id}</Typography>
        <Typography variant="caption" align='right'>Noise source component</Typography>
      </Grid>
      <Grid pb={1} item xs={12} sm={9}>
        <Typography>Operation setting: {subsource.setting?.id}</Typography>
      </Grid>
    </Grid>

    <Grid item xs={10} sm={6} p={1}>
      <Typography>Sound power level: {Math.round(subsource.setting?.sound_power_level)} dB(A)</Typography>
      <Typography><i>{subsource.setting?.level_reference}</i></Typography>
      {subsource.setting?.has_level_substitute &&
        <Typography variant="caption">
          <b>Note</b>: A sound power level from a
          similar noise source has been adopted
          for this item.
          <br />
          Contact RT&A for further information.
        </Typography>
      }
    </Grid>
    <Grid item xs={10} sm={6} p={1}>
      <Typography>RT&A spectrum code: {subsource.setting?.spectrum}</Typography>
      <Typography><i>{subsource.setting?.spectrum_reference}</i></Typography>
      {subsource.setting?.has_spectrum_substitute &&
        <Typography variant="caption">
          <b>Note</b>: A spectrum from a
          similar noise source has been adopted
          for this item.
          <br />
          Contact RT&A for further information.
        </Typography>
      }
    </Grid>
  </>
}


export default function SourceInfoModal({ noiseSource, isSourceInfoModalOpen, setIsSourceInfoModalOpen }) {
  const noiseSourceEntries = noiseSource.subsources?.map(subsource => {
    return <SubsourceCard subsource={subsource} />
  }).reduce((prev, curr) => [prev, <Grid item xs={12} py={1}><Divider /></Grid>, curr])

  return (
    <Dialog
      onClose={() => setIsSourceInfoModalOpen(false)}
      open={isSourceInfoModalOpen}
      scroll='paper'
      fullWidth
      maxWidth="lg">
      <DialogTitle>Noise source information</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography>Noise source: {noiseSource.source?.id}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Category: {noiseSource.source?.group}</Typography>
          </Grid>
          <Grid item py={1} xs={12}>
            <Divider />
          </Grid>
          {noiseSourceEntries}
        </Grid>
      </DialogContent>

    </Dialog>
  )

}
