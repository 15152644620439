import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";

export function activeSourcesReducer(state, action) {
    // expects:
    // {type: 'add', source: {...}}
    // or 
    // {type: 'remove', uuid: ...}
    // or 
    // {type: 'reset'}
    // or 
    // {type: 'loadFromScenario', sources: [...]}
  
    switch (action.type) {
      case 'add':
        if (action.source == null || _.isEmpty(action.source)) {
          console.log('you tried adding a null or empty source')
          return state;
        }
        console.log('hello we are adding a source')
        let newSource = action.source
        // this uuid lets us more easily find the source again if we need to remove it
        newSource.uuid = uuidv4()
        return [...state, newSource];
      case 'remove':
        console.log('hello we are removing source ' + action.uuid)
        let newState = state.filter(source => source.uuid !== action.uuid)
        return newState;
      case 'reset':
        return []
      case 'loadFromScenario':
        return action.sources
      default:
        console.log(`activeSources reducer: action type ${action.type} is not recognised`)
        return state;
    }
  }