import React, { useState } from 'react'
import {
  Collapse,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material"
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';

function NoiseLevelTypeDropdown({ activeNoiseResultType, setActiveNoiseResultType, noiseResultTypes }) {
  if (_.isEmpty(noiseResultTypes)) {
    console.log('no noise result typs')
    return
  }

  return <Grid py={1} item>
    <FormControl fullWidth>
      <InputLabel
        id="noise-level-type-label">
        Noise level type
      </InputLabel>
      <Select
        label="Noise level type"
        labelId='noise-level-type-label'
        size="small"
        value={activeNoiseResultType}
        onChange={(e) => setActiveNoiseResultType(e.target.value)}
      >
        {Object.keys(noiseResultTypes).map(noiseResultType => {
          return <MenuItem key={`noise-type-option-${noiseResultType}`} value={noiseResultType}>
            {noiseResultTypes[noiseResultType]}
          </MenuItem>
        })}
      </Select>
    </FormControl>
  </Grid>
}

function AdditionalDropdowns({
  colourScheme,
  activeNoiseResultType,
  setActiveNoiseResultType,
  noiseResultTypes
}) {

  switch (colourScheme.type) {
    case 'landuse':
    case 'heritage':
    case 'treated':
      return
    case 'noiselevel':
      return [<NoiseLevelTypeDropdown
        key='noise-level-type-dropdown'
        activeNoiseResultType={activeNoiseResultType}
        setActiveNoiseResultType={setActiveNoiseResultType}
        noiseResultTypes={noiseResultTypes} />]
    case 'noiseabove':
    case 'noisemitigation':
      return [<NoiseLevelTypeDropdown
        key='noise-level-type-dropdown'
        activeNoiseResultType={activeNoiseResultType}
        setActiveNoiseResultType={setActiveNoiseResultType}
        noiseResultTypes={noiseResultTypes} />]
    case 'vibrationlevel':
      return
    case 'vibrationabove':
    case 'vibrationmitigation':
      return
    default:
      return;
  }
}

function LegendBox({
  colourScheme, allColourSchemes, setColourScheme,
  activeNoiseResultType, setActiveNoiseResultType, noiseResultTypes
}) {

  const [visible, setVisible] = useState(true)

  const colourBands = colourScheme && colourScheme.scheme
  const legendItems = colourBands && Object.keys(colourBands).map(colourBand => {
    return (
      <Grid container alignItems="center">
        <Grid pr={2} item>
          <Icon sx={{ color: colourBands[colourBand] }}>
            <SquareRoundedIcon />
          </Icon>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {colourBand}
          </Typography>
        </Grid>
      </Grid>
    )
  })

  return <Paper
    sx={{
      position: "absolute",
      bottom: 30,
      right: 15
    }}>
    <Grid px={2} py={1} container direction="column">
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography>Legend</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <IconButton size="small" onClick={() => setVisible(!visible)}>
              {visible ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={visible}>
        <Grid pb={2} item>
          {legendItems}
        </Grid>
        <Grid item pb={1}>
          <FormControl fullWidth>
            <InputLabel id="colour-scheme-select-label">
              Colour scheme
            </InputLabel>
            <Select
              id="colour-scheme-select"
              labelId="colour-scheme-select-label"
              label="Colour scheme"
              size="small"
              fullWidth
              value={colourScheme}
              onChange={(e) => setColourScheme(e.target.value)}
            >
              {allColourSchemes
                .sort((a, b) => -(b.label).localeCompare(a.label))
                .map(option => {
                  return <MenuItem value={option}>{option.label}</MenuItem>
                })}
            </Select>
          </FormControl>
        </Grid>
        <AdditionalDropdowns
          colourScheme={colourScheme}
          activeNoiseResultType={activeNoiseResultType}
          setActiveNoiseResultType={setActiveNoiseResultType}
          noiseResultTypes={noiseResultTypes} />

      </Collapse>
    </Grid>
  </Paper>
}

export default LegendBox