import union from "@turf/union";
import * as geoViewport from "@mapbox/geo-viewport";
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import { MAX_ZOOM_FOR_VIEWPORT_CHANGE } from "./constants";


export function handleViewportChange(geometryOfInterest, setViewport) {
  let geometry = geometryOfInterest
  if (Array.isArray(geometryOfInterest)) {
    if (geometryOfInterest.length === 0) {
      console.log('nothing provided!')
      return
    } else if (geometryOfInterest.length === 1) {
      geometry = geometryOfInterest[0]
    } else {
      console.log('array of length > 1 received, trying to apply union...')
      let buffers = geometryOfInterest.map(g => {
        return buffer(g, 0.001)
      })
      geometry = union.apply(this, buffers)
    }
  }
  // make a buffer of the geometry
  let buffered = buffer(geometry, 0.005)  // 5m buffer, arbitrary choice
  // get a bouding box for the geometry
  let [minX, minY, maxX, maxY] = bbox(buffered)
  // calc viewport using the bounding box values
  const { innerWidth: width, innerHeight: height } = window;
  // step 3: pump through geoViewport
  const viewportData = geoViewport.viewport(
    [minX, minY, maxX, maxY],
    [width, height]
  );
  const newViewport = {
    latitude: viewportData.center[1],
    longitude: viewportData.center[0],
    zoom: Math.min(viewportData.zoom, MAX_ZOOM_FOR_VIEWPORT_CHANGE),
    bearing: 0,
    pitch: 0,
  };
  // set viewport
  setViewport(newViewport)
}