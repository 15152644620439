import { Grid, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useReducer, useState } from 'react'
import Step1Location from './Step1Location/Step1Location'
import Step2Source from './Step2Source/Step2Source'
import Step3Direction from './Step3Direction/Step3Direction'
import Step4SubSources from './Step4SubSources/Step4SubSources'
import Step5Review from './Step5Review/Step5Review'
import Step6Done from './Step6Done/Step6Done'

function noiseSourceReducer(state, action) {
  /*
  {
    location: {}, 
    source: {}, 
    direction: {}, 
    subsources: [], 
    startTime: datetime,   // To be implemented
    endTime: datetime,     // To be implemented
  }
  */

  // NOTE it is up to each Step in the Add Source flow to validate what's in the noiseSource state.
  // The reducer only does what it is told e.g. if it's told to update location, it will do just that.
  switch (action.type) {
    case 'reset':
      return {}
    case 'updateLocation':
      return { ...state, location: action.location }
    case 'updateSource':
      return { ...state, source: action.source }
    case 'updateDirection':
      return {
        ...state,
        direction: action.direction
      }
    case 'updateSubSources':
      // subsources should look like:
      // [{id: ..., description: ..., state: ...},
      //  {id: ..., description: ...},
      //  ...]
      // the array shall include only active subsources
      return { ...state, subsources: action.subsources }
    default:
      console.log('noiseSourceReducer: unrecognised action ', action.type)
      return state
  }
}

export default function AddSourceFormContents({
  projectId,
  sourceLocations,
  activeSourcesDispatch,
  zoomToSourceLocation }) {
  const [noiseSource, noiseSourceDispatch] = useReducer(noiseSourceReducer, {})
  const [activeStep, setActiveStep] = useState(0)

  function handleNext() {
    setActiveStep(thisStep => thisStep + 1)
  }

  function handleBack() {
    setActiveStep(thisStep => thisStep - 1)
  }

  function handleReset() {
    noiseSourceDispatch({ type: 'reset' })
    setActiveStep(0)
  }

  const steps = [
    {
      'label': 'Location',
      'content': <Step1Location
        noiseSource={noiseSource}
        sourceLocations={sourceLocations}
        noiseSourceDispatch={noiseSourceDispatch}
        handleNext={handleNext}
        zoomToSourceLocation={zoomToSourceLocation} />
    },
    {
      'label': 'Noise source',
      'content': <Step2Source
        noiseSource={noiseSource}
        projectId={projectId}
        noiseSourceDispatch={noiseSourceDispatch}
        handleNext={handleNext}
        handleBack={handleBack} />
    },
    {
      'label': 'Direction',
      'content': <Step3Direction
        noiseSource={noiseSource}
        noiseSourceDispatch={noiseSourceDispatch}
        handleNext={handleNext}
        handleBack={handleBack} />
    },
    {
      'label': 'Components',
      'content': <Step4SubSources
        noiseSource={noiseSource}
        noiseSourceDispatch={noiseSourceDispatch}
        handleNext={handleNext}
        handleBack={handleBack} />
    },
    {
      'label': 'Review',
      'content': <Step5Review
        noiseSource={noiseSource}
        activeSourcesDispatch={activeSourcesDispatch}
        handleNext={handleNext}
        handleBack={handleBack} />
    }
  ]

  return (
    <Grid item>
      <Grid pb={2} item>
        <Typography component="h1" variant="h5">
          Add source
        </Typography>
      </Grid>
      <Grid pb={1} item>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            return <Step key={index}><StepLabel>{step.label}</StepLabel></Step>
          })}
        </Stepper>
      </Grid>
      {activeStep === steps.length ? <Step6Done handleReset={handleReset} /> : steps[activeStep].content}
    </Grid>
  )
}
