// TODO this is for development only, colour schemes should be defined in a file or database elsewhere

const landUseColourScheme = {
  "Childcare": "#bc01ff",
  "Commercial": "#f8ff2b",
  "Educational": "#ffa601",
  "Hotel/Motel/Hostel": "#f06292",
  "Industrial": "#969a16",
  "Medical": "#ead588",
  "Mixed-use": "#2bfcff",
  "Place of Worship": "#73c7f7",
  "Recreational - Active": "#ecffb2",
  "Recreational - Passive": "#006104",
  "Residential": "#0123ff"
}

const noiseLevelColourScheme = {
  "35 or less": "#2743B1",
  "36-40": "#3791C8",
  "41-45": "#5CC1DD",
  "46-50": "#9AE2EF",
  "51-55": "#DAF6FA",
  "56-60": "#FEE7AD",
  "61-65": "#FDC17A",
  "66-70": "#F28755",
  "71-75": "#CF3B31",
  "76 or higher": "#920030"
}

const DEFAULT_COLOUR = "#506667"

export { landUseColourScheme, DEFAULT_COLOUR, noiseLevelColourScheme }