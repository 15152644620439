import { Grid, Typography } from '@mui/material'
import React from 'react'
import BackNextButtons from '../BackNextButtons'

export default function Step5Review({ noiseSource, activeSourcesDispatch, handleNext, handleBack }) {
  function handleSubmit() {
    activeSourcesDispatch({ type: "add", source: noiseSource })
    handleNext()
  }

  return (
    <>
      <Grid pb={1} item>
        <Typography>Location: {noiseSource.location.id}</Typography>
        <Typography>Noise source: {noiseSource.source.id}</Typography>
        {noiseSource.direction && <Typography>Direction: {noiseSource.direction.id}</Typography>}
      </Grid>
      <Grid container pb={2}>
        {noiseSource.subsources && <>
          <Grid item xs={12}>
            <Typography>Source components</Typography>
          </Grid>
          {noiseSource.subsources.map(subsource => {
            return (<>
              <Grid item sm={subsource.setting ? 4 : 12}>
                Name: {subsource.id}
              </Grid>
              {subsource.setting && <Grid item sm={8}>Operation setting: {subsource.setting.id}</Grid>}
            </>
            )
          })}
        </>
        }
      </Grid>
      <BackNextButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        nextButtonDisabled={false}
      />
    </>
  )
}
