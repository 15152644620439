import { LoadingButton } from '@mui/lab'
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { EMPTY_SCENARIO, SCENARIO_MENU } from '../../constants'
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, writeBatch } from 'firebase/firestore'
import { db, gatewave } from '../../../firebase'


function newScenarioReducer(state, action) {
  switch (action.type) {
    case 'load':
      return { ...action.scenario, template: false }
    case 'update':
      return { ...state, [action.updated]: action.value }
    case 'clear':
      return EMPTY_SCENARIO;
    default:
      return state;
  }
}

export default function SaveScenarioMenu({
  activeProject,
  activeScenario,
  setActiveScenario,
  activeSources,
  infoBoxDispatch
}) {
  const [newScenario, newScenarioDispatch] = useReducer(newScenarioReducer, EMPTY_SCENARIO)
  const [isSaveInProgress, setIsSaveInProgress] = useState(false)
  const [isSaveAsNewInProgress, setIsSaveAsNewInProgress] = useState(false)

  useEffect(() => {
    if (activeScenario) {
      newScenarioDispatch({ type: 'load', scenario: activeScenario })
    }
  }, [activeScenario])

  const scenariosRef = collection(gatewave, activeProject.id, 'Scenario')

  async function saveScenario(id, scenario, saveAsNew) {
    const scenarioToSave = {...scenario, 'saved': serverTimestamp()}
    let scenarioRef
    if (saveAsNew) {
      scenarioRef = await addDoc(scenariosRef, scenarioToSave)
    } else {
      scenarioRef = doc(scenariosRef, id)
      await setDoc(scenarioRef, scenarioToSave)
    }

    // Retrieve the existing documents in the collection
    const scenarioSourcesRef = collection(scenarioRef, 'Sources')
    const scenarioSourcesQuery = query(scenarioSourcesRef)
    const scenarioSourcesSnapshot = await getDocs(scenarioSourcesQuery)
    const existingIds = scenarioSourcesSnapshot.docs.map(doc => doc.id)

    // Compare IDs and keep track of any IDs that are not in the array
    const deletedIds = existingIds.filter(id => !activeSources.find(obj => obj.id === id))

    // Update or create Firestore documents
    const batch = writeBatch(db)
    activeSources.forEach(obj => {
      let docRef
      if (obj.id) {
        docRef = doc(scenarioSourcesRef, obj.id)
      } else {
        docRef = doc(scenarioSourcesRef)
      }
      batch.set(docRef, obj)
    });

    deletedIds.forEach(async id => {
      const docRef = doc(scenarioSourcesRef, id)
      batch.delete(docRef)
    })
    // Save
    await batch.commit()
    
    const savedScenario = await getDoc(scenarioRef)
    return {id: savedScenario.id, ...savedScenario.data()}
  }

  function handleSaveButtonClick(saveButtonStateSetter, saveAsNew) {
    saveButtonStateSetter(true)
    let { id, ...scenario } = newScenario
    saveScenario(id, scenario, saveAsNew).then((savedScenario) => {
      saveButtonStateSetter(false)
      setActiveScenario(savedScenario)
      infoBoxDispatch({ type: SCENARIO_MENU })
    })
  }

  return (
    <>
      <Grid item mb={2}>
        <Typography variant="h5">Save scenario</Typography>
        {newScenario.id && !activeScenario.template && <Typography variant="caption">
          Note: click Save As New to avoid overwriting this scenario.
        </Typography>}
      </Grid>
      <Grid px={2} my={1} item>
        <TextField
          fullWidth
          value={newScenario.reference}
          onChange={(e) => newScenarioDispatch({ type: 'update', updated: 'reference', value: e.target.value })}
          id="id-input"
          label="ID or reference"
          size="small"
          variant="standard"
        />
      </Grid>
      <Grid px={2} my={1} item>
        <TextField
          fullWidth
          value={newScenario.name}
          onChange={(e) => newScenarioDispatch({ type: 'update', updated: 'name', value: e.target.value })}
          id="name-input"
          label="Scenario name"
          size="small"
          variant="standard"
        />
      </Grid>
      <Grid px={2} my={1} item>
        <TextField
          fullWidth
          multiline
          minRows={3}
          value={newScenario.description}
          onChange={(e) => newScenarioDispatch({ type: 'update', updated: 'description', value: e.target.value })}
          id="description-input"
          label="Description and additional notes"
          size="small"
          variant="standard"
        />
      </Grid>
      <Grid px={2} my={1} item>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Mark as template?"
            value={newScenario.template}
            onChange={(e) => newScenarioDispatch({ type: 'update', updated: 'template', value: e.target.checked })}
          />
        </FormGroup>
      </Grid>
      <Grid my={1} item>
        <Divider />
      </Grid>
      <Grid pt={1} container textAlign="center" justifyContent="center">
        <Grid mx={2}>
          <LoadingButton
            variant="contained"
            color="success"
            disabled={activeScenario?.template || isSaveAsNewInProgress}
            loading={isSaveInProgress}
            onClick={() => handleSaveButtonClick(setIsSaveInProgress, false)}
          >
            Save
          </LoadingButton>
        </Grid>
        <Grid mx={2} item>
          <LoadingButton
            variant="outlined"
            color="success"
            disabled={isSaveInProgress}
            loading={isSaveAsNewInProgress}
            onClick={() => handleSaveButtonClick(setIsSaveAsNewInProgress, true)}
          >
            Save as new
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  )
}
