import { Button, Grid } from "@mui/material";

export default function BackNextButtons({handleBack, handleSubmit, nextButtonDisabled}) {
    return <Grid container>
    <Grid item xs={6}>
      <Button variant="contained" onClick={handleBack} color="secondary">Back</Button>
    </Grid>
    <Grid item xs={6} textAlign="right">
      <Button variant="contained" onClick={handleSubmit} disabled={nextButtonDisabled}>Next</Button>
    </Grid>
  </Grid>
}