import React from 'react'
import BaseItem from '../BaseItem'
import HomeIcon from '@mui/icons-material/Home';
import { PROJECT_MENU } from '../../constants';

export default function ProjectMenuItem({ isSidebarOpen, infoBoxDispatch }) {
  return (
    <BaseItem
      text={"Project menu"}
      icon={<HomeIcon />}
      isSidebarOpen={isSidebarOpen} 
      onClick={() => infoBoxDispatch({type: PROJECT_MENU})}/>
  )
}