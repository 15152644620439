import { VARIABLE } from "./constants"

export function getAdjustmentLabel(
  availableAdjustments,
  selectedAdjustment,
  baseLw,
  giveOnlyEqualAdjustments) {

  if (selectedAdjustment === -Infinity) {
    return "Cannot operate"
  }

  if (availableAdjustments === VARIABLE && selectedAdjustment != null) {
    return "Plant Lw = " + Math.round(baseLw + selectedAdjustment, 0)
  } else if (availableAdjustments && selectedAdjustment != null) {
    let filteredAdjustments;
    if (giveOnlyEqualAdjustments) {
      filteredAdjustments = Object.keys(availableAdjustments).filter(adjustment => {
        return availableAdjustments[adjustment] === selectedAdjustment
      })
    } else {
      filteredAdjustments = Object.keys(availableAdjustments).filter(adjustment => {
        return availableAdjustments[adjustment] <= selectedAdjustment
      })
    }
    if (filteredAdjustments.length === 0) {
      return "Cannot operate"
    } else {
      // sort by adjustment amount, highest to lowest
      let toReturn = filteredAdjustments.sort((a, b) => {
        return availableAdjustments[a] > availableAdjustments[b]
      })
      return toReturn.join("; ")
    }
  } else {
    return "-"
  }
}


export function setLevelAdjustmentProperly(setLevelAdjustment, currentAdjustment, availableAdjustments, requiredAdjustment) {
  if (currentAdjustment != null) {
    setLevelAdjustment(currentAdjustment)
  } else {
    if (availableAdjustments == null) {
      setLevelAdjustment(requiredAdjustment)
    } else if (availableAdjustments === VARIABLE) {
      setLevelAdjustment(requiredAdjustment)
    } else {
      const allComplyingAdjustments = Object.values(availableAdjustments).filter(adjustment => adjustment <= requiredAdjustment)
      const highestComplyingAdjustment = Math.max(...allComplyingAdjustments)
      setLevelAdjustment(highestComplyingAdjustment)
    }
  }
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}



