import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useEffect } from 'react'

export default function Step1Location({
  noiseSource,
  sourceLocations,
  noiseSourceDispatch,
  handleNext,
  zoomToSourceLocation }) {
  // Step 1: select location
  const [location, setLocation] = useState()

  useEffect(() => {
    if (noiseSource && noiseSource.location) {
      setLocation(noiseSource.location)
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // this effect will change the viewport when the location changes
    console.log('useEffect location')
    try {
      zoomToSourceLocation(location)
    } catch {
      console.log('zoom to source location failed')
    }
  }, [location]) //eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit() {
    console.log('location ', location)
    noiseSourceDispatch({ type: "updateLocation", location: location })
    handleNext()
  }

  const nextButtonDisabled = _.isEmpty(location)
  
  return (
    <>
      <Grid pb={1} item>
        <Autocomplete
          id="location-select"
          options={sourceLocations?.sort((a, b) => -(b.category + b.name).localeCompare(a.category + a.name))}
          value={location}
          groupBy={(option) => option ? option.category : ""}
          getOptionLabel={(option) => option ? option.name : ""}
          renderInput={(params) => <TextField {...params} label="Select location" />}
          onChange={(e, selectedLocation) => setLocation(selectedLocation)}
        />
      </Grid>
      <Grid textAlign="right">
        <Button variant="contained" onClick={handleSubmit} disabled={nextButtonDisabled}>Next</Button>
      </Grid>
    </>
  )
}
