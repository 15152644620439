// Admin Panel is for admins only!

import {
  Checkbox,
  FormControl, FormControlLabel,
  InputLabel, ListItemText,
  MenuItem, OutlinedInput,
  Select, TextField,
  Typography,
  Box, Button,
  FormHelperText,
  Table, TableHead,
  TableRow, TableCell,
  TableBody,
  List,
  ListItem,
  ListItemIcon,
  Divider
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import React, { useReducer, useState } from 'react'
import newUsersReducer from '../newUsersReducer'
import { useEffect } from 'react';
import { getAllProjects } from '../../queries/getDataQueries';
import createUsers from './createUsers';


export default function UserTab() {
  // the state we care about while building up the list of new users
  const [newUserName, setNewUserName] = useState("")
  const [newUserEmail, setNewUserEmail] = useState("")
  const [isNewUserAdmin, setIsNewUserAdmin] = useState(false)
  const [isNewUserEngineer, setIsNewUserEngineer] = useState(false)
  const [selectedProjectsAccess, setSelectedProjectsAccess] = useState([])
  const [selectedProjectsEdit, setSelectedProjectsEdit] = useState([])
  // the state we care about when we hit submit
  const [newUsers, newUsersDispatch] = useReducer(newUsersReducer, [])
  // other
  const [statusMessage, setStatusMessage] = useState(null)
  // all projects
  const [allProjects, setAllProjects] = useState([])
  // results of clicking create users
  const [createdUsers, setCreatedUsers] = useState([])

  useEffect(() => {
    getAllProjects().then(result => {
      setAllProjects(result)
    })
  }, [])

  function addNewUser(e) {
    e.preventDefault()
    console.log('adding new user...')
    newUsersDispatch({
      type: 'add',
      name: newUserName,
      email: newUserEmail,
      isAdmin: isNewUserAdmin,
      isEngineer: isNewUserEngineer,
      canAccess: selectedProjectsAccess,
      canEdit: selectedProjectsEdit
    })
    setNewUserName("")
    setNewUserEmail("")
  }

  function removeNewUser(user) {
    newUsersDispatch({
      type: 'remove',
      email: user.email
    })
  }

  function resetUserList() {
    newUsersDispatch({
      type: 'reset'
    })
  }

  function handleSelectProjects(e, projectStateFunction) {
    const {
      target: { value },
    } = e;
    projectStateFunction(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  function handleCreateUsers() {
    //e.preventDefault()
    setStatusMessage("adding users...")
    setCreatedUsers([])
    createUsers(newUsers).then(results => {
      console.log(results)
      let newlyCreatedUsers = results.map(result => {
        switch (result.status) {
          case 'rejected':
            return { success: false, email: result.reason?.email, message: result.reason?.responseText }
          case 'fulfilled':
            return { success: true, email: result.value?.email, message: result.value?.responseText }
          default:
            return null
        }
      })
      setCreatedUsers(newlyCreatedUsers)
      setStatusMessage("")
    })
  }

  return (
    <>
      <Typography variant="h3">Add users</Typography>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        component="form"
        onSubmit={addNewUser}
      >
        <TextField
          id="userName"
          required
          label="User's full name"
          value={newUserName}
          margin="normal"
          onChange={(e) => setNewUserName(e.target.value)} />
        <TextField
          id="userEmail"
          label="User's email"
          required
          margin="normal"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)} />
        <FormControlLabel
          control={<Checkbox value={isNewUserAdmin}
            onChange={e => setIsNewUserAdmin(e.target.checked)} />}
          label="Admin?" />
        <FormControlLabel
          control={<Checkbox value={isNewUserEngineer}
            onChange={e => setIsNewUserEngineer(e.target.checked)} />}
          label="Engineer?" />
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="project-access-multiple-label">Can access projects</InputLabel>
          <Select
            labelId="project-access-multiple-label"
            id="project-access-multiple"
            multiple
            value={selectedProjectsAccess}
            onChange={(e) => handleSelectProjects(e, setSelectedProjectsAccess)}
            input={<OutlinedInput label="Can access projects" />}
            renderValue={(selected) => selected.join(', ')}
            disabled={isNewUserAdmin}
          >
            {allProjects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                <Checkbox checked={selectedProjectsAccess.indexOf(project.id) > -1} />
                <ListItemText primary={project.id} secondary={project.name} />
              </MenuItem>
            ))}
          </Select>
          {isNewUserAdmin && <FormHelperText>Note: no need to select projects for admins, they can access everything</FormHelperText>}
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="project-edit-multiple-label">Can edit projects</InputLabel>
          <Select
            labelId="project-edit-multiple-label"
            id="project-edit-multiple"
            multiple
            value={selectedProjectsEdit}
            onChange={(e) => handleSelectProjects(e, setSelectedProjectsEdit)}
            input={<OutlinedInput label="Can edit projects" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {allProjects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                <Checkbox checked={selectedProjectsEdit.indexOf(project.id) > -1} />
                <ListItemText primary={project.id} secondary={project.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" type="submit">Add user</Button>
      </Box>
      <Typography variant="h5">Users to be added</Typography>
      {newUsers && newUsers.length > 0 ?
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Privileges
              </TableCell>
              <TableCell>
                Can access projects
              </TableCell>
              <TableCell>
                Can edit projects
              </TableCell>
              <TableCell>
                Remove?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newUsers.map(newUser => {
              return (
                <TableRow>
                  <TableCell>
                    {newUser.name}
                  </TableCell>
                  <TableCell>
                    {newUser.email}
                  </TableCell>
                  <TableCell>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          {newUser.isAdmin ? <DoneIcon /> : <CloseIcon />}
                        </ListItemIcon>
                        <ListItemText primary="Admin" />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          {newUser.isEngineer ? <DoneIcon /> : <CloseIcon />}
                        </ListItemIcon>
                        <ListItemText primary="Engineer" />
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    {newUser.canAccess?.join(", ")}
                  </TableCell>
                  <TableCell>
                    {newUser.canEdit?.join(", ")}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="error" onClick={() => removeNewUser(newUser)}>Remove</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        : <Typography>None yet...</Typography>}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="success"
          disabled={!newUsers || newUsers.length === 0}
          onClick={() => handleCreateUsers()}>
          Create users
        </Button>
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="error"
          disabled={!newUsers || newUsers.length === 0}
          onClick={() => resetUserList()}>
          Clear list
        </Button>
        {statusMessage ? <FormHelperText>{statusMessage}</FormHelperText> : null}
      </Box>
      {createdUsers && createdUsers.length > 0 &&
        <>
          <Divider />
          <Typography>Results</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Email address
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Message
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {createdUsers.map(createdUser => {
                return <TableRow>
                  <TableCell>
                    {createdUser.email}
                  </TableCell>
                  <TableCell>
                    {createdUser.success ? "Success" : "Failed"}
                  </TableCell>
                  <TableCell>
                    {createdUser.message}
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </>
      }
    </>
  )
}
