import { AppBar, Button, Grid, Toolbar, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import EquipmentTab from './EquipmentTab/EquipmentTab'
import UserTab from './UserTab/UserTab'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { isAdmin } from '../queries/permissionQueries'
import { auth } from '../firebase'


export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState("home")
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isAnAdmin, setIsAnAdmin] = useState(null)

  useEffect(() => {
    if (user) {
      isAdmin(user).then(result => {
        setIsAnAdmin(result)
      })
    }
  }, [user])

  if (isAnAdmin == null) {
    return <Grid container direction="column" alignItems="center" minHeight="100vh" justifyContent="center">
      <Grid item>
        <Typography>Checking your credentials...</Typography>
      </Grid>
    </Grid>
  }

  if (isAnAdmin != null && !isAnAdmin) {
    return navigate("/")
  }

  let activeTabContents = <Typography>Select a tab</Typography>;
  switch (activeTab) {
    case "home":
      activeTabContents = (
        <Typography>
          Engineer's panel - add/remove users, add/remove/assign equipment
        </Typography>
      )
      break;
    case "users":
      activeTabContents = <UserTab />
      break;
    case "equipment":
      activeTabContents = <EquipmentTab />
      break;
    default:
      break;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Engineer panel
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button key="users" sx={{ color: '#fff' }} onClick={() => setActiveTab("users")}>
              Users
            </Button>
            <Button key="equipment" sx={{ color: '#fff' }} onClick={() => setActiveTab("equipment")}>
              Equipment
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Toolbar />
        {activeTabContents}
      </Container>
    </Box>
  )
}
