import { React } from "react";
import { Card, CardHeader, CardContent, Typography } from '@mui/material';

function ReceiverPopupContent({
  receiver, applicableCriteria,
  receiverPartialLevels, aggregatedLevel,
  exceedance, criteriaLevels }) {

  let noiseFromEachSource = []
  let criteriaLevel = criteriaLevels[receiver.properties.BEZ]

  if (receiverPartialLevels != null) {
    let levelsArray = []
    Object.keys(receiverPartialLevels).forEach(key => {
      levelsArray.push([key, receiverPartialLevels[key]])
    })
    levelsArray.sort((a, b) => { return b[1] - a[1] })
    noiseFromEachSource = levelsArray.map(entry => {
      return <Typography variant="body2" key={"recPartLvl-" + receiver.properties.BEZ + entry[0]}>
        <b>{entry[0]}</b>: {entry[1]} dBA L<sub>eq15min</sub>
      </Typography>
    })
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader title={receiver.properties ? receiver.properties.BEZ : null} subheader="Receiver" />
      <CardContent>
        <Typography variant="body2">
          Level: {aggregatedLevel && aggregatedLevel > 0 ? aggregatedLevel + " dBA Leq15min" : "n/a"}
        </Typography>
        {criteriaLevel ?
          <Typography variant="body2">
            Criteria ({applicableCriteria}): {criteriaLevel} dBA L<sub>eq15min</sub>
          </Typography>
          : null}
        {exceedance && exceedance > 0 ?
          <Typography variant="body2" color="#f74726">
            dB above {applicableCriteria}: {exceedance} dB
          </Typography>
          : null}
        {noiseFromEachSource && noiseFromEachSource.length > 0 ?
          <>
            <hr />
            <Typography variant="body2">
              Noise from each source: {noiseFromEachSource}
            </Typography>
          </> : null}
      </CardContent>
    </Card>
  );
}

export default ReceiverPopupContent;
