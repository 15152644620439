import React from "react";
import { Typography, Grid, Box, Tabs, Tab, Tooltip, Divider } from '@mui/material';
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const fieldsToIgnore = ['ADDRESS', 'heritage', 'level']
const specialFields = {
  'HA': 'Building height (metres)',
  'rectype': 'Land use'
}
const noiseLevelLabelMap = { 'standard': "Standard", 'noise enhancing': "Noise enhancing" }
const noiseLevelTooptipContent = {
  'standard': <>
    <Typography variant="body2">Standard (calm) propagation conditions</Typography>
    <Typography variant="body2">Wind speed: 0 m/s</Typography>
    <Typography variant="body2">Stability category: D</Typography>
  </>,
  'noise enhancing': <>
    <Typography variant="body2">Noise-enhancing propagation conditions</Typography>
    <Typography variant="caption">As defined in the NSW EPA Noise Policy for Industry</Typography>
    <Typography variant="body2">Wind speed: 2 m/s</Typography>
    <Typography variant="body2">Stability category: F</Typography>
  </>
}

function ResultTabPanel({ building, value }) {
  return <TabPanel value={value} index={0}>
    {building.properties && building.properties.level ?
      Object.keys(building.properties.level).map(levelType => {
        let level = building.properties.level[levelType]
        level = Math.round(level, 0)
        if (level < 20) {
          level = "< 20"
        }
        let noiseLevel = "-" 
        if (level) {
          noiseLevel = `${level} dB(A)`
        }
        return <Tooltip arrow title={noiseLevelTooptipContent[levelType]}>
          <Typography>
            {noiseLevelLabelMap[levelType] || levelType}: {noiseLevel}
          </Typography>
        </Tooltip>
      })
      : "No results loaded"}
  </TabPanel>
}

function LandUseTabPanel({ building, value, noiseCriteriaFields, vibrationCriteriaFields }) {
  let tabNoiseContent = <Typography variant="caption">
    Noise criteria yet to be defined.<br />
    Contact RT&A for more information.
  </Typography>

  if (noiseCriteriaFields) {
    let noiseFieldsToMap = noiseCriteriaFields.filter(field => Object.keys(building.properties).includes(field))
    if (noiseFieldsToMap.length === 0) {
      tabNoiseContent = noiseFieldsToMap.map(field => {
        return <Typography variant="body2">
          {field}: {building.properties[field]}
        </Typography>
      })
    } else {
      tabNoiseContent = <Typography variant="caption">
        No noise criteria defined for this building.
      </Typography>
    }
  }

  let tabVibrationContent = <Typography variant="caption">
    Vibration criteria yet to be defined.<br />
    Contact RT&A for more information.
  </Typography>

  if (vibrationCriteriaFields) {
    let vibrationFieldsToMap = vibrationCriteriaFields.filter(field => Object.keys(building.properties).includes(field))
    if (vibrationFieldsToMap.length === 0) {
      tabVibrationContent = vibrationFieldsToMap.map(field => {
        return <Typography variant="body2">
          {field}: {building.properties[field]}
        </Typography>
      })
    } else {
      tabVibrationContent = <Typography variant="caption">
        No vibration criteria defined for this building.
      </Typography>
    }
  }

  return <TabPanel value={value} index={1}>
    <Grid container direction="column">
      <Grid item>
        <Typography>{building.properties.rectype}</Typography>
      </Grid>
      <Grid my={1} item>
        <Divider />
      </Grid>
      <Grid item>
        {tabNoiseContent}
      </Grid>
      <Grid my={1} item>
        <Divider />
      </Grid>
      <Grid item>
        {tabVibrationContent}
      </Grid>
    </Grid>
  </TabPanel>
}

function InformationTabPanel({ building, value }) {
  return <TabPanel value={value} index={2}>
    {Object.keys(building.properties)
      .filter(prop => !fieldsToIgnore.includes(prop))
      .map(prop => {
        let propName = prop
        if (specialFields[prop]) {
          propName = specialFields[prop]
        }
        try {
          return <Typography>
            {propName}: {building.properties[prop]}
          </Typography>
        } catch {
          console.log(`could not render ${propName}`)
          return null
        }
      })}
  </TabPanel>
}

export default function BuildingPopupContent({
  building,
  noiseCriteriaFields,
  vibrationCriteriaFields
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      minWidth="280px">
      <Grid item pb={1}>
        <Typography variant="h6">
          {building.properties.ADDRESS}
        </Typography>
        <Typography variant="caption">
          Building
        </Typography>
      </Grid>
      <Grid item flexGrow={1} display="flex" alignItems="center">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Results" {...a11yProps(0)} />
          <Tab label="Land use" {...a11yProps(1)} />
          <Tab label="Information" {...a11yProps(2)} />
        </Tabs>
        <ResultTabPanel
          building={building}
          value={value}
        />
        <LandUseTabPanel
          building={building}
          value={value}
          noiseCriteriaFields={noiseCriteriaFields}
          vibrationCriteriaFields={vibrationCriteriaFields}
        />
        <InformationTabPanel
          building={building}
          value={value}
        />
      </Grid>
    </Grid>
  );
}
