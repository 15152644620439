import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { collection, getDoc, getDocs, orderBy, query, doc, where, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { gatewave } from '../../../firebase'
import { SOURCE_MENU } from '../../constants'
import { useCollection } from 'react-firebase-hooks/firestore';
import _ from 'lodash'
import dayjs from 'dayjs'


export default function LoadScenarioMenu({
  infoBoxDispatch,
  activeProject,
  activeSourcesDispatch,
  activeScenario,
  setActiveScenario
}) {
  const scenariosRef = collection(gatewave, activeProject.id, 'Scenario')
  const scenarioQuery = query(scenariosRef, where('deleted', '==', false), orderBy('name'))
  const [scenarioSnapshot, loading, error] = useCollection(scenarioQuery)
  const [scenarios, setScenarios] = useState([])

  useEffect(() => {
    if (_.isEmpty(scenarioSnapshot) || scenarioSnapshot.empty) {
      console.log('no scenarios or snapshot is undefined')
      setScenarios([])
    } else {
      const newScenarios = []
      scenarioSnapshot.forEach(doc => {
        newScenarios.push({ id: doc.id, ...doc.data() })
      })
      setScenarios(newScenarios)
    }
  }, [scenarioSnapshot])

  function loadScenario(scenarioId) {
    console.log(scenarioId)
    const selectedScenarioRef = doc(scenariosRef, scenarioId)
    const sourcesRef = collection(selectedScenarioRef, 'Sources')
    const sourceQuery = query(sourcesRef)

    getDoc(selectedScenarioRef).then(result => {
      if (result.empty) {
        alert('Error - could not find scenario')
      } else {
        let loadedScenario = { id: result.id, ...result.data() }
        console.log(loadedScenario)
        setActiveScenario(loadedScenario)
      }
    })

    getDocs(sourceQuery).then(result => {
      if (result.empty) {
        activeSourcesDispatch({ type: 'reset' })
      } else {
        const sources = result.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        activeSourcesDispatch({ type: 'loadFromScenario', sources: sources })
      }
      infoBoxDispatch({ type: SOURCE_MENU })
    })
  }

  function deleteScenario(scenarioId) {
    const selectedScenarioRef = doc(scenariosRef, scenarioId)
    updateDoc(selectedScenarioRef, {
      deleted: true
    }).then(() => {
      console.log(`marked scenario ${scenarioId} as deleted`)
    })
  }

  return (
    <>
      <Typography variant="h5">Scenarios</Typography>
      <Typography maxWidth="50vw">{error?.message}</Typography>
      {
        loading ? <Typography>Loading...</Typography> :
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Last saved at
                </TableCell>
                <TableCell>
                  Template?
                </TableCell>
                <TableCell>
                  Load scenario
                </TableCell>
                <TableCell>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scenarios && scenarios.map(scenario => {
                return (
                  <TableRow>
                    <TableCell>
                    <Typography variant="body2">{scenario.reference}</Typography>
                      {_.isEqual(scenario, activeScenario) &&
                        <Typography
                          sx={{ color: "success.main" }}
                          variant="caption">
                          Current scenario
                        </Typography>}
                    </TableCell>
                    <TableCell>
                      {scenario.name}
                    </TableCell>
                    <TableCell>
                      {scenario.description}
                    </TableCell>
                    <TableCell>
                      {dayjs.unix(scenario.saved.seconds).format("h:mmA DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {scenario.template ? "Yes" : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        variant={_.isEqual(scenario, activeScenario) ? "outlined" : "contained"}
                        color={_.isEqual(scenario, activeScenario) ? "success" : "primary"}
                        onClick={() => loadScenario(scenario.id)}
                      >
                        {_.isEqual(scenario, activeScenario) ? "Reload" : "Load"}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => deleteScenario(scenario.id)}
                        disabled={scenario.template}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
      }
    </>
  )
}
