import React from 'react'
import BaseItem from '../BaseItem'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SOURCE_MENU } from '../../constants';

export default function SourceMenuItem({ isSidebarOpen, infoBoxDispatch }) {
  return (
    <BaseItem
      text={"Source menu"}
      icon={<VolumeUpIcon />}
      isSidebarOpen={isSidebarOpen} 
      onClick={() => infoBoxDispatch({type: SOURCE_MENU})}/>
  )
}