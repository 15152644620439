import { getBlob, listAll, ref } from "firebase/storage"
import { geometryStorage } from "../firebase";
import { getSourceLocations } from "../queries/getDataQueries";

function getGeometry(folderPath, dispatcher) {
  // get every geojson file in folderPath,
  // then pull out the features and condense into one list.
  // TODO make it so it only pulls out geojson files
  const cloudFolder = ref(geometryStorage, folderPath)
  listAll(cloudFolder).then(res => {
    res.items.forEach((itemRef) => {
      // All the items in the cloud folder
      getBlob(itemRef).then(data => {
        data.text().then((parsedData) => {
          let featuresToAdd = JSON.parse(parsedData).features
          dispatcher({ type: 'add', features: featuresToAdd })
        })
      })
    });
  })
}

export function getAndSetProjectGeometry(
  activeProject,
  receiversDispatch,
  sourceGeometryDispatch,
  buildingsDispatch,
  projectExtentDispatch) {
  if (activeProject && activeProject.id) {
    // fetch receivers
    getGeometry(`${activeProject.id}/Receiver`, receiversDispatch)
    // fetch sources
    getGeometry(`${activeProject.id}/Source`, sourceGeometryDispatch)
    // fetch buildings
    getGeometry(`${activeProject.id}/Building`, buildingsDispatch)
    // fetch project extent
    getGeometry(`${activeProject.id}/ProjectExtent`, projectExtentDispatch)
  }
}

export function getAndSetProjectDocuments(activeProject, setScenarios, setSourceLocations) {
  if (activeProject && activeProject.id) {
    getSourceLocations(activeProject.id).then(sourceLocations => {
      setSourceLocations(sourceLocations)
    }).catch(error => {
      console.log('error getting locations: ', error)
    })
  }
}
