import { Checkbox, Collapse, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import React from 'react'
import { IMAGERY_OPTIONS } from '../constants'
import { useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DisplayOptionsBox({
  currentImageryStyle,
  setCurrentImageryStyle,
  layerDisplaySettings,
  layerDisplaySettingsDispatch
}) {
  const [visible, setVisible] = useState(false)
  return (
    <Paper sx={{
      position: "absolute",
      top: 10,
      right: 15
    }}>
      <Grid direction="column" container py={1} px={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>Display options</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={() => setVisible(!visible)}>
              {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={visible}>
          <Grid pt={2} item>
            <FormControl size="small" fullWidth>
              <InputLabel id="imagery-select-label">Imagery</InputLabel>
              <Select
                labelId="imagery-select-label"
                id="imagery-select"
                label="Imagery"
                value={currentImageryStyle}
                onChange={e => setCurrentImageryStyle(e.target.value)}
              >
                {IMAGERY_OPTIONS.map(option => {
                  return <MenuItem value={option}>{option.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid pt={1} container direction="column">
            <Grid item>
              Show features
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Buildings"
                checked={layerDisplaySettings.showBuildings}
                onChange={(e) => layerDisplaySettingsDispatch({
                  type: 'update',
                  field: 'showBuildings',
                  value: e.target.checked
                })}
                control={<Checkbox />} />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Receivers"
                checked={layerDisplaySettings.showReceivers}
                onChange={(e) => layerDisplaySettingsDispatch({
                  type: 'update',
                  field: 'showReceivers',
                  value: e.target.checked
                })}
                control={<Checkbox />} />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item>
              <Typography>
                Show receiver types
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Residential"
                checked={layerDisplaySettings.showResidential}
                onChange={(e) => layerDisplaySettingsDispatch({
                  type: 'update',
                  field: 'showResidential',
                  value: e.target.checked
                })}
                control={<Checkbox />} />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Mixed-use"
                checked={layerDisplaySettings.showMixedUse}
                onChange={(e) => layerDisplaySettingsDispatch({
                  type: 'update',
                  field: 'showMixedUse',
                  value: e.target.checked
                })}
                control={<Checkbox />} />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Non-residential"
                checked={layerDisplaySettings.showNonResidential}
                onChange={(e) => layerDisplaySettingsDispatch({
                  type: 'update',
                  field: 'showNonResidential',
                  value: e.target.checked
                })}
                control={<Checkbox />} />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Paper>
  )
}
