import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useReducer } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import SubSourceForm from './SubSourceForm/SubSourceForm';
import BackNextButtons from '../BackNextButtons';


function activeSubSourceReducer(state, action) {
  // expects:
  // {type: 'activate', subsource: {id: ..., description: ..., state: ...}}
  // {type: 'deactivate': subsourceId: ...}
  // {type: 'reset'}
  let newState
  switch (action.type) {
    case 'activate':
      // need to overwrite the subsource if it's in state already
      newState = [...state]
      let existingEntryIndex = state.findIndex(subsource => subsource.id === action.subsource.id)
      if (existingEntryIndex >= 0) {
        newState[existingEntryIndex] = action.subsource
      } else {
        newState.push(action.subsource)
      }
      return newState;
    case 'deactivate':
      newState = state.filter(subsource => subsource.id !== action.subsourceId)
      return newState;
    case 'reset':
      return []
    default:
      break;
  }
}

export default function Step4SubSources({ noiseSource, noiseSourceDispatch, handleNext, handleBack }) {
  const [activeSubSources, activeSubSourcesDispatch] = useReducer(activeSubSourceReducer, [])
  const [allSubSources, setAllSubSources] = useState([])
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  // this should fire each time the step is selected
  // but if it doesn't, add noiseSource to the dependency array
  useEffect(() => {
    let sourceIsValid = noiseSource && noiseSource.source && noiseSource.source.id
    let hasSubSources = sourceIsValid && noiseSource.direction.subsources
    if (hasSubSources) {
      setAllSubSources(noiseSource.direction.subsources || [])
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(activeSubSources)
    if (allSubSources.length > 0 && activeSubSources.length === 0) {
      setNextButtonDisabled(true)
      return
    }

    // if all active subsources have a setting defined, then OK to proceed
    if (activeSubSources.filter(subsource => subsource.setting == null).length === 0) {
      setNextButtonDisabled(false)
      return
    }

    // TODO check that subsources with settings have a setting defined
    // activeSubSources.filter(activeSubSource => )
    setNextButtonDisabled(true)

  }, [activeSubSources, allSubSources])

  function handleSubmit() {
    noiseSourceDispatch({ type: "updateSubSources", subsources: activeSubSources })
    handleNext()
  }

  return (
    <>
      {allSubSources.length === 0 ? <Typography>No source component options for this noise source</Typography>
        : <Box sx={{ m: 1 }}>
          {allSubSources.map(subsource => {
            return <SubSourceForm
              subsource={subsource}
              activeSubSourcesDispatch={activeSubSourcesDispatch}
            />
          })}
        </Box>
      }
      <BackNextButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        nextButtonDisabled={nextButtonDisabled}
      />
    </>
  )
}
