import { DELETE, TERRAIN_LAYER, VIEW } from "./constants";

export default function handleMapHover(info, deckRef, drawMode, drawnFeaturesDispatch) {
  if (drawMode !== VIEW && drawMode !== DELETE) {
    const deck = deckRef.current.deck;
    let newCoords
    if (info.coordinate) {
      let terrainSelection = deck.pickObject({
        x: info.pixel[0],
        y: info.pixel[1],
        unproject3D: true,
        layerIds: [TERRAIN_LAYER]
      })
      if (terrainSelection?.coordinate) {
        newCoords = terrainSelection.coordinate
      } else {
        console.log(info.coordinate)
        newCoords = [...info.coordinate, 0]
      }
      drawnFeaturesDispatch({ type: 'nextVertex', nextVertex: newCoords, mode: drawMode })
    }
  }

  return true
}