import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { auth, gatewave, userpermissions } from "../firebase";

async function getAllDocuments(collectionPath) {
  const querySnapshot = await getDocs(collectionPath)
  let result = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    let newObject = { id: doc.id, ...doc.data() }
    result.push(newObject)
  });
  return result
}

export async function getAllProjects() {
  const userId = auth.currentUser.uid
  const userPermissionsRef = doc(userpermissions, userId)
  const userPermissions = (await getDoc(userPermissionsRef)).data()
  if (userPermissions.isAdmin || userPermissions.isEngineer) {
    return await getAllDocuments(gatewave)
  }
  const canAccess = userPermissions.canAccess || []
  const canEdit = userPermissions.canEdit || []
  const conditions = where("__name__", "in", [...canAccess, ...canEdit])
  const projectQuery = query(gatewave, conditions)
  return await getAllDocuments(projectQuery)
}

export async function getSourceLocations(projectId) {
  return await getAllDocuments(collection(gatewave, projectId, 'SourceLocation'))
}

export async function getSourcesAtLocation(projectId, locationId) {
  const sourceCollection = collection(gatewave, projectId, 'SourceLocation', locationId, 'Sources')
  const sourceDocuments = await getAllDocuments(sourceCollection)
  return sourceDocuments
}

export async function getSourceDirections(projectId, locationId, sourceId) {
  const sourceDocument = await getDoc(doc(gatewave, projectId, 'SourceLocation', locationId, 'Sources', sourceId))
  return sourceDocument.data().directions || []
}