export default function newUsersReducer(state, action) {
  // expects:
  // {type: 'add', email: <str>, name: <str>, isAdmin: true/false, isEngineer: true/false}
  // or 
  // {type: 'remove', email: <str>}
  // or 
  // {type: 'reset'}

  let { type: actionType, ...newUser } = action;
  let otherUsers = state.filter(user => user.email !== newUser.email)
  switch (actionType) {
    case 'add':
      console.log('hello we added ' + newUser.email)
      return [...otherUsers, newUser];
    case 'remove':
      console.log('hello we removed ' + newUser.email)
      return otherUsers
    case 'reset':
      return []
    default:
      console.log('newUsers reducer: action type is not recognised')
      return state;
  }
}