import React, { useState } from "react"
import { Tab, Tabs, Grid } from "@mui/material"
import { Box } from "@mui/system"
import SourceTabContents from "./SourceTabContents/SourceTabContents"
import AddSourceTabContents from "../../AddSourceFormContents/AddSourceFormContents"


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ContentTabs({ activeTab, setActiveTab, cardContents }) {
  function handleChange(event, newValue) {
    setActiveTab(newValue)
  }
  return <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
      {Object.keys(cardContents).map((element, index) => {
        return <Tab value={element} label={element} {...a11yProps(index)} />
      })}
    </Tabs>
  </Box>
}

export default function SourceMenu({
  activeProject,
  activeSources,
  activeSourcesDispatch,
  sourceLocations,
  zoomToSourceLocation }) {

  const projectId = activeProject.id

  const cardContents = {
    'Active sources': <SourceTabContents
      activeSources={activeSources}
      activeSourcesDispatch={activeSourcesDispatch}
      zoomToSourceLocation={zoomToSourceLocation}
    />,
    'Add source': <AddSourceTabContents
      projectId={projectId}
      sourceLocations={sourceLocations}
      activeSourcesDispatch={activeSourcesDispatch}
      zoomToSourceLocation={zoomToSourceLocation}
    />
  }
  const [activeTab, setActiveTab] = useState(Object.keys(cardContents)[0])

  return (
    <>
      <ContentTabs activeTab={activeTab} setActiveTab={setActiveTab} cardContents={cardContents} />
      <Grid pt={2} container>
        {cardContents[activeTab]}
      </Grid>
    </>
  )
}
