import React from 'react'
import { useState } from 'react'
import { calculateNoise } from '../../calculation'
import BaseItem from '../BaseItem'
import CalculateIcon from '@mui/icons-material/Calculate';

export default function CalculateButton({ isSidebarOpen, projectId, activeSources, setNoiseResults }) {
  const [isCalculating, setIsCalculating] = useState(false)

  function handleClick() {
    if (isCalculating) {
      return
    }

    setIsCalculating(true)
    calculateNoise(projectId, activeSources, setNoiseResults).then(result => {
      console.log('calc function worked')
    }).catch(error => {
      console.log('error with calc function ', error)
    }).finally(() => {
      setIsCalculating(false)
    })
  }

  return (
    <BaseItem
      text={isCalculating ? "Running..." : "Calculate"}
      icon={<CalculateIcon />}
      isSidebarOpen={isSidebarOpen}
      onClick={handleClick} />
  )
}