import { GeoJsonLayer } from "@deck.gl/layers";
import { SOURCE_LAYER } from "../../constants";

function getSourceFillColour(source, activeSources, isPolygon) {
  let transparency
  if (isPolygon) {
    transparency = 150
  } else {
    transparency = 255
  }
  const filteredActiveSources = activeSources.filter(x => x.location.name === source.properties.name)
  if (filteredActiveSources.length > 0) {
    return [234, 209, 1, transparency]
  } else {
    return [103, 33, 185, transparency]
  }
}

function getSourceLineColour(source, activeSources) {
  const filteredActiveSources = activeSources.filter(x => x.location.name === source.properties.name)
  if (filteredActiveSources.length > 0) {
    return [128, 8, 53, 255]
  } else {
    return [103, 33, 185, 255]
  }
}

export default function makeSourceLayer(sources,
  activeSources,
  activeCategories) {

  let data = sources.filter(feature => {
    let featureCategory = feature.properties.category;
    if (activeCategories && activeCategories.length > 0) {
      return activeCategories.includes(featureCategory) || activeSources[feature.properties.name];
    } else {
      return true
    }
  });

  const pointProps = {
    getFillColor: (source) => getSourceFillColour(source, activeSources, true),
    lineWidthUnits: 'pixels',
    getLineWidth: 4,
    getLineColor: (source) => getSourceLineColour(source, activeSources),
    getPointRadius: 3,
    lineJointRounded: true,
    lineCapRounded: true,
    lineWidthMaxPixels: 6
  }

  const textProps = {
    getText: (source) => source.properties.name,
    getTextSize: 18,
    textFontFamily: "Poppins",
    textOutlineColor: [255, 255, 255],
    textOutlineWidth: 6,
    getTextPixelOffset: [0, -8],
    textSizeMaxPixels: 16,
    textFontSettings: { sdf: true, buffer: 100 }
  }

  return new GeoJsonLayer({
    id: SOURCE_LAYER,
    data,
    pickable: true,
    pointType: 'circle+text',
    updateTriggers: {
      getFillColor: [activeSources]
    },
    ...pointProps,
    ...textProps,
    _subLayerProps: {
      'points-text': {
        pickable: false
      }
    }
  });
}
