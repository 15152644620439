import { ChevronLeft, ChevronRight, Logout } from '@mui/icons-material'
import { Divider, List, ListItemText } from '@mui/material'
import { signOut } from 'firebase/auth'
import React from 'react'
import { auth } from '../../../firebase'
import { SidebarListItem, SidebarListItemButton, SidebarListItemIcon } from '../styledListComponents'

export default function SidebarFooterList({isSidebarOpen, toggleSidebar}) {
  return (
    <List>
      <Divider />
      <SidebarListItem key={"Logout"} disablePadding>
        <SidebarListItemButton
          isSidebarOpen={isSidebarOpen}
          onClick={() => signOut(auth)}
        >
          <SidebarListItemIcon isSidebarOpen={isSidebarOpen}>
            <Logout />
          </SidebarListItemIcon>
          {isSidebarOpen ? <ListItemText primary={"Logout"} /> : null}
        </SidebarListItemButton>
      </SidebarListItem>
      <Divider />
      <SidebarListItem key={"Minimise"} disablePadding sx={{ display: 'block' }}>
        <SidebarListItemButton
          isSidebarOpen={isSidebarOpen}
          onClick={toggleSidebar}
        >
          <SidebarListItemIcon isSidebarOpen={isSidebarOpen}>
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </SidebarListItemIcon>
          {isSidebarOpen ? <ListItemText primary={"Minimise"} /> : null}
        </SidebarListItemButton>
      </SidebarListItem>
    </List>
  )
}
