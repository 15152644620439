/* What is this function doing?

* It's letting you sample the terrain height
* It's letting you choose multiple overlapping objects

What it will need to be smart about
* Does the click event care at all about the features or just the terrain?
* Do we need to let the user choose which feature they were trying to click?

*/

import _ from "lodash";
import {
  BUILDING_LAYER, RECEIVER_LAYER, SOURCE_LAYER, DRAWN_FEATURES_LAYER,
  DELETE, DRAW_WORKAREA, DRAW_OBSERVATION, VIEW, EDIT, MEASURE, MEASURE_AREA, TERRAIN_LAYER
} from "./constants";

const layersWithPopups = [BUILDING_LAYER, SOURCE_LAYER, RECEIVER_LAYER]
const layersWithDeletableFeatures = [DRAWN_FEATURES_LAYER]

function handlePopup(selectedObjects, popupDispatch, popupProps) {
  const popupEnabledObjects = selectedObjects.filter(item => layersWithPopups.includes(item.layer.id))
  if (popupEnabledObjects.length > 0) {
    popupDispatch({ type: 'openPopup', features: popupEnabledObjects, popupProps: popupProps })
  }
}

export default function handleMapClick(
  event,
  info,
  deckRef,
  popupDispatch,
  drawMode,
  setDrawMode,
  drawnFeaturesDispatch, 
  popupProps
) {
  // handleMapClick needs to handle these types of clicking interactions:
  // * Selecting features on the map
  // * Clicking to form geometry

  if (info.rightButton) {
    setDrawMode(VIEW)
    drawnFeaturesDispatch(drawnFeaturesDispatch({
      type: 'reset',
      mode: VIEW
    }))
    return
  }

  const deck = deckRef.current.deck;
  let selectedObjects = deck.pickMultipleObjects({ x: event.x, y: event.y, unproject3D: true })
  console.log(drawMode)
  selectedObjects = _.uniq(selectedObjects)
  const terrainSelection = deck.pickObject({
    x: event.x,
    y: event.y,
    unproject3D: true,
    layerIds: [TERRAIN_LAYER]
  })
  let newCoords
  if (terrainSelection?.coordinate) {
    newCoords = terrainSelection.coordinate
  } else {
    newCoords = [...event.coordinate, 0]
  }

  switch (drawMode) {
    case DRAW_WORKAREA:
      // fire an addVertex
      drawnFeaturesDispatch({
        type: 'addVertex',
        coordinates: newCoords,
        mode: DRAW_WORKAREA
      })
      break;
    case DRAW_OBSERVATION:
      // fire an addFeature
      drawnFeaturesDispatch({
        type: 'addFeature',
        coordinates: newCoords,
        mode: DRAW_OBSERVATION
      })
      break;
    case EDIT:
      drawnFeaturesDispatch({
        type: 'editFeature',
        coordinates: newCoords,
        mode: EDIT
      })
      break;
    case MEASURE_AREA:
      // fire an addVertex
      drawnFeaturesDispatch({
        type: 'addVertex',
        coordinates: newCoords,
        mode: MEASURE_AREA
      })
      break;
    case MEASURE:
      // fire an addVertex
      drawnFeaturesDispatch({
        type: 'addVertex',
        coordinates: newCoords,
        mode: MEASURE
      })
      break;
    case DELETE:
      const deletableObjects = selectedObjects.filter(item => layersWithDeletableFeatures.includes(item.layer.id))
      drawnFeaturesDispatch({ type: 'removeFeatures', deletableObjects: deletableObjects })
      break;
    case VIEW:
      handlePopup(selectedObjects, popupDispatch, popupProps)
      break;
    default:
      handlePopup(selectedObjects, popupDispatch, popupProps)
      break;
  }

  // return a truthy value to stop propagation (it should stop here anyway)
  return true
};