import _ from "lodash"
import { auth } from "../firebase"

const CALC_ENDPOINT = "https://airport-calc-73l6zpenyq-ts.a.run.app"
//const CALC_ENDPOINT = "http://127.0.0.1:8080"

export async function calculateNoise(projectId, activeSources, setNoiseResults) {
  if (!projectId || _.isEmpty(activeSources)) {
    console.log('project id or active sources are not defined')
    return
  }

  // TODO change to noiseResultDispatch
  // and then get clever about how we store and present levels + levels per source

  console.log('calculating...')
  const payload = {
    projectId: projectId,
    activeSources: activeSources
  }
  const authToken = await auth.currentUser.getIdToken()
  return fetch(CALC_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('results obtained')
      setNoiseResults(data)
    }).catch(error => {
      console.log('error ', error)
    })
}