import { DRAW_WORKAREA } from "../constants";
import { STANDARD_PROPERTIES } from "../constants";
import { DRAW_OBSERVATION } from "../constants";
import { MEASURE_AREA } from "../constants";
import { MEASURE } from "../constants";

/*
{alreadyDrawn: EMPTY_GEOJSON, nextVertex: [], fixedVertices: [], nextFeatureType: <mode>}
*/


function createFeature(coordinates, drawMode) {
  let newFeature = { 'coordinates': coordinates }
  switch (drawMode) {
    case DRAW_WORKAREA:
      if (coordinates.length > 2) {
        newFeature['type'] = 'Polygon'
        newFeature['properties'] = STANDARD_PROPERTIES[DRAW_WORKAREA]
        return newFeature
      }
      break;
    case DRAW_OBSERVATION:
      if (coordinates.length === 1) {
        newFeature['type'] = 'Point'
        newFeature['properties'] = STANDARD_PROPERTIES[DRAW_OBSERVATION]
        return newFeature
      }
      break
    case MEASURE:
      if (coordinates.length >= 2) {
        newFeature['type'] = 'LineString'
        return newFeature
      }
      break;
    case MEASURE_AREA:
      if (coordinates.length > 2) {
        newFeature['type'] = 'Polygon'
        return newFeature
      }
      break;
    default:
      break;
  }

  return
}

export default function drawnFeaturesReducer(state, action) {

  switch (action?.type) {
    case 'reset':
      return { ...state, nextVertex: [], nextFeatureType: action.mode }
    case 'nextVertex':
      // this is to facilitate the hover
      //console.log({ ...state, nextVertex: action.nextVertex, nextFeatureType: action.mode })
      return { ...state, nextVertex: action.nextVertex, nextFeatureType: action.mode }

    case 'addVertex':
      // we rely on who sent the dispatch to know if they want to add vertex or complete the feature
      console.log(state)
      if (action.coordinates) {
        return {
          ...state,
          nextVertex: [],
          fixedVertices: [...state.fixedVertices, action.coordinates],
          nextFeatureType: action.mode
        }
      }
      break

    case 'addFeature':
      let newFeature = createFeature(state.fixedVertices, state.nextFeatureType)
      let updatedFeatures = [...state.alreadyDrawn.features, newFeature]
      return {
        ...state,
        alreadyDrawn: { ...state.alreadyDrawn, features: updatedFeatures },
        nextVertex: [],
        fixedVertices: []
      }

    default:
      return state
  }

}