import { LAYER_DISPLAY_SETTINGS } from "../constants";

export default function layerDisplaySettingsReducer(state, action) {
  switch (action.type) {
    case 'update':
      return {...state, [action.field]: action.value}
    case 'reset':
      return LAYER_DISPLAY_SETTINGS
    default:
      return state
  }
}