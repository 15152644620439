import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import SourceInfoModal from "./SourceInfoModal/SourceInfoModal";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';


export default function SourceTabContents({
  activeSources,
  activeSourcesDispatch,
  zoomToSourceLocation
}) {

  const [isSourceInfoModalOpen, setIsSourceInfoModalOpen] = useState(false)
  const [selectedSource, setSelectedSource] = useState({})

  function removeNoiseSource(noiseSource) {
    activeSourcesDispatch({ type: 'remove', uuid: noiseSource.uuid })
  }

  function openSourceInfoModal(noiseSource) {
    setSelectedSource(noiseSource)
    setIsSourceInfoModalOpen(true)
  }

  return <Paper style={{ maxHeight: "100%", overflow: 'auto' }} elevation={0}>
    <SourceInfoModal
      noiseSource={selectedSource}
      isSourceInfoModalOpen={isSourceInfoModalOpen}
      setIsSourceInfoModalOpen={setIsSourceInfoModalOpen}
    />
    <Typography variant="h5">
      Source details
    </Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Location
            </TableCell>
            <TableCell>
              Noise source
            </TableCell>
            <TableCell>
              Direction
            </TableCell>
            <TableCell align="center">
              Components and operation setting
            </TableCell>
            <TableCell align="center">
              More info
            </TableCell>
            <TableCell align="center">
              Remove?
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeSources && activeSources.map(activeSource => {
            return <TableRow>
              <TableCell>
                {activeSource.location.name}
                <Tooltip title="Click to zoom to location" arrow>
                  <IconButton onClick={() => zoomToSourceLocation(activeSource.location)}>
                    <TravelExploreIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                {activeSource.source.id}
              </TableCell>
              <TableCell>
                {activeSource.direction.id}
              </TableCell>
              <TableCell>
                <Table size="small">
                  <TableBody>
                    {activeSource.subsources.map(subsource => {
                      return (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell>
                            {subsource.id}
                          </TableCell>
                          <TableCell>
                            {subsource.setting && subsource.setting.id}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell align="center">
                <Button variant="contained" size="small" color="info" onClick={() => openSourceInfoModal(activeSource)}>Info</Button>
              </TableCell>
              <TableCell align="center">
                <Button variant="contained" size="small" color="error" onClick={() => removeNoiseSource(activeSource)}>Remove</Button>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>

  </Paper>
}