import { GeoJsonLayer } from "@deck.gl/layers";import { EMPTY_GEOJSON, TEMPORARY_FEATURE_LAYER } from "../../constants";
import _ from "lodash";


const SET_POINT = 'setPoint'
const IN_PROGRESS_POINT = 'inProgressPoint'
const TEMPORARY_FEATURE = 'temporaryFeature'


const FILLCOLOUR_VALUES = {
  [SET_POINT]: [113, 176, 122, 150],
  [IN_PROGRESS_POINT]: [208, 102, 108, 200],
  [TEMPORARY_FEATURE]: [139, 159, 171, 100]
}

const LINECOLOUR_VALUES = {
  [SET_POINT]: [45, 70, 48],
  [IN_PROGRESS_POINT]: [244, 220, 159],
  [TEMPORARY_FEATURE]: [55, 63, 68]
}

const LINEWIDTH_VALUES = {
  [SET_POINT]: 2,
  [IN_PROGRESS_POINT]: 3,
  [TEMPORARY_FEATURE]: 1
}


export default function makeTemporaryFeatureLayer(
  drawnFeatures
) {
  // TODO consider having this function make multiple layers

  // This layer needs to display the following: 
  // * The clicked points on the map 
  // * The point on the map where the user's cursor is sitting
  // * The in-progress 

  const {fixedVertices, nextVertex} = drawnFeatures

  const pointsToDisplay = fixedVertices.map(v => {
    return { 'type': 'Feature', 'properties': { 'displayType': SET_POINT }, 'geometry': {'type': 'Point', 'coordinates': v} }
  })

  pointsToDisplay.push({ 'type': 'Feature', 'properties': { 'displayType': IN_PROGRESS_POINT }, 'geometry': {'type': 'Point', 'coordinates': nextVertex} })
  
  let tempPolygonCoords = [...fixedVertices]
  if (!_.isEmpty(nextVertex)) {
    tempPolygonCoords.push(nextVertex)
  }
  const temporaryFeature = { 'type': 'Feature', 'geometry': {'type': 'Polygon', 'coordinates': [tempPolygonCoords]}, properties: { 'displayType': TEMPORARY_FEATURE } }
  
  const data = { ...EMPTY_GEOJSON, features: [...pointsToDisplay, temporaryFeature] }
  
  return new GeoJsonLayer({
    id: TEMPORARY_FEATURE_LAYER,
    data: data,
    pickable: false,
    getPointRadius: 3,
    pointRadiusMinPixels: 3,
    getFillColor: (f) => FILLCOLOUR_VALUES[f.properties.displayType],
    getLineColor: (f) => LINECOLOUR_VALUES[f.properties.displayType],
    getLineWidth: (f) => LINEWIDTH_VALUES[f.properties.displayType],
    pointBillboard: true
  });
}