import { GeoJsonLayer } from "@deck.gl/layers";
import { DRAWN_FEATURES_LAYER } from '../../constants';

export default function makeDrawnFeaturesLayer(
  drawnFeatures
) {
  return new GeoJsonLayer({
    id: DRAWN_FEATURES_LAYER,
    data: drawnFeatures.alreadyDrawn,
    pickable: true,
  });
}