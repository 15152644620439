import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { getAllProjects } from "../../../queries/getDataQueries"
import { CLOSED_INFOBOX } from "../../constants"


export default function ProjectMenu({
  activeProject,
  setActiveProject,
  infoBoxDispatch
}) {

  const [allProjects, setAllProjects] = useState([])

  useEffect(() => {
    getAllProjects().then(result => {
      setAllProjects(result)
      console.log('Projects identified: ', result)
    })
  }, [])

  function handleSelectProject(projectId) {
    let selectedProject = allProjects.find(project => project.id === projectId)
    if (selectedProject) {
      setActiveProject(selectedProject)
      infoBoxDispatch({ type: CLOSED_INFOBOX })
    } else {
      setActiveProject({})
    }
  }

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
        <InputLabel id="project-small">Select project</InputLabel>
        <Select
          labelId="project-small"
          id="project-select"
          label="Select project"
          value={activeProject ? activeProject.id : ""}
          onChange={e => handleSelectProject(e.target.value)}
        >
          {allProjects ?
            allProjects.map(option => {
              return <MenuItem value={option.id}>
                {option.description ? `${option.id}: ${option.description}` : option.id}
              </MenuItem>
            })
            : null}
        </Select>
      </FormControl>
    </>
  )
}
