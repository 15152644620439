import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import BaseItem from '../BaseItem'
import FolderIcon from '@mui/icons-material/Folder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import { SAVE_SCENARIO_MENU, SCENARIO_MENU } from '../../constants';

function ScenarioSubList({ infoBoxDispatch }) {
  return (
    <List component="div" disablePadding>
      <ListItemButton sx={{ pl: 4 }} onClick={() => infoBoxDispatch({type: SCENARIO_MENU})}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Load scenario" />
      </ListItemButton>
      <ListItemButton sx={{ pl: 4 }} onClick={() => infoBoxDispatch({type: SAVE_SCENARIO_MENU})}>
        <ListItemIcon>
          <NoteAddIcon />
        </ListItemIcon>
        <ListItemText primary="Save scenario" />
      </ListItemButton>
    </List>
  )
}

function ScenarioItem({ isSidebarOpen, infoBoxDispatch }) {
  return (
    <BaseItem
      text={"Scenarios"}
      icon={<FolderIcon />}
      isSidebarOpen={isSidebarOpen}
      subList={<ScenarioSubList infoBoxDispatch={infoBoxDispatch} />} />
  )
}

export { ScenarioItem }