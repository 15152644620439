import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

export default function Step6Done({ handleReset }) {
  function handleSubmit() {
    handleReset()
  }

  return (
    <>
      <Grid item pb={1}>
        <Typography>Noise source has been added</Typography>
      </Grid><Grid item pb={2}>
        <Button color="info" variant="contained" onClick={handleSubmit}>Add new</Button>
      </Grid>
    </>
  )
}
