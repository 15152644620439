import { initializeApp } from "firebase/app";
import {
  getAuth
} from "firebase/auth";
import {
  getFirestore,
  collection
} from "firebase/firestore";
import { getStorage } from "firebase/storage"


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChsyGvyml3kBTyccXdcBx0pGDzhIm8kyE",
  authDomain: "rtagatewave.firebaseapp.com",
  databaseURL: "https://rtagatewave.firebaseio.com",
  projectId: "rtagatewave",
  storageBucket: "rtagatewave.appspot.com",
  messagingSenderId: "110935085859",
  appId: "1:110935085859:web:b37ef8f6eb94ac395a3296",
  measurementId: "G-HEFBXHMGPN"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const gatewave = collection(db, 'Gatewave')
export const userpermissions = collection(db, 'UserPermissions')
export const geometryStorage = getStorage(app, "gs://gatewave-geometry");
